import {
  React,
  useState,
  createContext,
  useContext
} from 'react';
import api from 'src/services/api';
import { SiteContext } from './SiteContext';

export const ClientContext = createContext();

const ClientProvider = (prop) => {
  const baseUrl = 'admin/client';
  const { children } = prop;
  const [client, setClient] = useState(null);
  const [clients, setClients] = useState([]);
  const [urlPaginate, setUrlPaginate] = useState();
  const {
    setNotifyMessage,
    notifyMessageSave,
    closeNotifyMessage,
    notifyMessageError,
    loading,
    setLoading
  } = useContext(SiteContext);
  const [search, setSearch] = useState();

  const getClients = async () => {
    setLoading(true);
    await api.get(`${baseUrl}/all`)
      .then((response) => {
        setClients(response.data);
        setUrlPaginate(response.data.meta.path);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getClient = async (id) => {
    setLoading(true);
    await api.get(`${baseUrl}/get/${id}`)
      .then((response) => {
        setClient(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const saveClient = async (data) => {
    setLoading(true);
    const serverResponse = await api.post(baseUrl, data)
      .then((response) => {
        setClient(response.data.data);
        setLoading(false);
        notifyMessageSave('Salvo');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
    return serverResponse;
  };

  const loginAsClient = async (data) => {
    setLoading(true);
    const serverResponse = await api.post(`${baseUrl}/loginAs`, data)
      .then((response) => {
        if (response.data) {
          setLoading(false);
          return response.data;
        }

        return null;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return null;
      });
    return serverResponse;
  };

  const updateClient = async (id, data) => {
    setLoading(true);
    await api.post(`${baseUrl}/update/${id}`, data)
      .then((response) => {
        setClient(response.data.data);
        setLoading(false);
        notifyMessageSave('Atualizado');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const searchClients = async (param) => {
    setLoading(true);
    if (!param) {
      await getClients();
    } else {
      await api.post(`${baseUrl}/search`, { param })
        .then((response) => {
          setClients(response.data);
          setUrlPaginate(response.data.meta.path);
          setSearch(param);
          setLoading(false);
        })
        .catch((err) => {
          setClients([]);
          setLoading(false);
          console.log(err);
        });
    }
  };

  const paginateClient = async (number) => {
    setLoading(true);
    if (search) {
      await api.post(`${urlPaginate}?page=${number}`, { search })
        .then((response) => {
          setClients(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setClients([]);
          setLoading(false);
          console.log(err);
        });
    } else {
      await api.get(`${urlPaginate}?page=${number}`)
        .then((response) => {
          setClients(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setClients([]);
          setLoading(false);
          console.log(err);
        });
    }
  };

  const deleteClient = async (id) => {
    setLoading(true);
    const response = await api.delete(`${baseUrl}/delete/${id}`)
      .then(() => {
        setLoading(false);
        notifyMessageSave('excluido');
        getClients();
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
    return response;
  };

  return (
    <ClientContext.Provider
      value={{
        client,
        clients,
        loading,
        urlPaginate,
        getClients,
        saveClient,
        updateClient,
        setClient,
        deleteClient,
        paginateClient,
        getClient,
        loginAsClient,
        setNotifyMessage,
        searchClients,
        closeNotifyMessage,
        notifyMessageError,
        notifyMessageSave
      }}
    >
      { children }
    </ClientContext.Provider>

  );
};

export default ClientProvider;
