import { Helmet } from 'react-helmet';
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import PropTypes from 'prop-types';
import { BranchContext } from 'src/context/BranchContext';
import BranchProfileDetails from 'src/components/branch/BranchProfileDetails';
import { ProductContext } from 'src/context/ProductContext';
import ProductList from 'src/components/product/ProductList';

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const tab = {
  color: '#FFF'
};

const tabSelected = {
  color: '#FFF',
  borderBottom: '5px solid red'
};

const BranchForm = () => {
  const { id, client } = useParams();
  const navigate = useNavigate();
  const {
    loading,
    branch,
    setBranch,
    getBranch,
    saveBranch,
    updateBranch,
    getGeoLocation,
  } = useContext(BranchContext);

  const { products, getProductsByBranch } = useContext(ProductContext);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(async () => {
    if (id) {
      await getBranch(id);
      getProductsByBranch(id);
    } else {
      setBranch(null);
    }
  }, []);

  const backClient = () => {
    if (branch) {
      navigate(`/app/client/${branch.client_id}`, { replace: true });
    } else {
      navigate(`/app/client/${client}`, { replace: true });
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {`Client | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          {loading === false
            ? (
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      marginBottom: '10px',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={backClient}
                    >
                      Voltar
                    </Button>
                  </Box>
                  <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                      <Tab style={value === 0 ? tabSelected : tab} label="Dados" {...a11yProps(0)} />
                      <Tab disabled={!branch} style={value === 1 ? tabSelected : tab} label="Produtos" {...a11yProps(1)} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={value} index={0} style={{ backgroundColor: '#fff' }}>
                    <BranchProfileDetails
                      branch={branch}
                      saveform={saveBranch}
                      updateform={updateBranch}
                      getGeoLocation={getGeoLocation}
                    />
                  </TabPanel>
                  { id
                    ? (
                      <>
                        <TabPanel value={value} index={1} style={{ backgroundColor: '#fff' }}>
                          <ProductList products={products} />
                        </TabPanel>
                      </>
                    )
                    : <></> }
                </Grid>
              </Grid>
            )
            : <></> }
        </Container>
      </Box>
    </>
  );
};
export default BranchForm;
