import {
  React,
  useState,
  createContext,
  useContext
} from 'react';
import api from 'src/services/api';
import { SiteContext } from './SiteContext';

export const PlanBenefitsContext = createContext();

const PlanBenefitsProvider = (prop) => {
  const baseUrl = 'admin/planBenefits';
  const { children } = prop;
  const [planBenefit, setPlanBenefit] = useState(null);
  const [planBenefits, setPlanBenefits] = useState([]);
  const [planBenefitsSettings, setPlanBenefitsSettings] = useState([]);
  const [urlPaginate, setUrlPaginate] = useState();
  const {
    setNotifyMessage,
    notifyMessageSave,
    closeNotifyMessage,
    notifyMessageError,
    loading,
    setLoading
  } = useContext(SiteContext);

  const getAllPlanBenefits = async () => {
    setLoading(true);
    await api.get(`${baseUrl}/all`)
      .then((response) => {
        setPlanBenefits(response.data);
        setPlanBenefitsSettings(response.data.meta);
        setUrlPaginate(response.data.meta.path);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getPlanBenefitsByPlan = async (plan) => {
    setLoading(true);
    try {
      const response = await api.get(`${baseUrl}/all/byPlan/${plan}`);
      console.log('API response:', response.data); // Adicionando console.log para depuração
      setPlanBenefits(response.data);
      if (response.data.meta) {
        setPlanBenefitsSettings(response.data.meta);
        setUrlPaginate(response.data.meta.path);
        console.log('Updated planBenefitsSettings:', response.data.meta);
      } else {
        console.error('Meta data not found in response');
      }
    } catch (err) {
      console.error('API request error:', err);
    } finally {
      setLoading(false);
    }
  };

  const getPlanBenefit = async (id) => {
    try {
      const response = await api.get(`${baseUrl}/get/${id}`);
      setPlanBenefit(response.data.data);
      return response.data.data;
    } catch (err) {
      console.error(err);
      return null; // Retorne null ou false para indicar falha
    }
  };

  const savePlanBenefit = async (data) => {
    const serverResponse = await api.post(`${baseUrl}/create`, data)
      .then(async () => {
        notifyMessageSave('Salvo');
        await getPlanBenefitsByPlan(data.plan_id);
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
    return serverResponse;
  };

  const updatePlanBenefit = async (id, data) => {
    setLoading(true);
    await api.post(`${baseUrl}/update/${id}`, data)
      .then(async () => {
        notifyMessageSave('Atualizado');
        await getPlanBenefitsByPlan(data.plan_id);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const paginatePlanBenefits = async (number) => {
    setLoading(true);
    await api.get(`${urlPaginate}?page=${number}`)
      .then((response) => {
        setPlanBenefits(response.data);
        setPlanBenefitsSettings(response.data.meta);
        setLoading(false);
      })
      .catch((err) => {
        setPlanBenefits([]);
        setLoading(false);
        console.log(err);
      });
  };

  const deletePlanBenefit = async (id, planId) => {
    setLoading(true);
    const response = await api.delete(`${baseUrl}/delete/${id}`)
      .then(() => {
        setLoading(false);
        notifyMessageSave('excluido');
        getPlanBenefitsByPlan(planId);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
    return response;
  };

  return (
    <PlanBenefitsContext.Provider
      value={{
        planBenefit,
        planBenefits,
        deletePlanBenefit,
        savePlanBenefit,
        updatePlanBenefit,
        paginatePlanBenefits,
        loading,
        urlPaginate,
        planBenefitsSettings,
        getAllPlanBenefits,
        getPlanBenefitsByPlan,
        setPlanBenefits,
        getPlanBenefit,
        setNotifyMessage,
        closeNotifyMessage,
        notifyMessageError,
        notifyMessageSave
      }}
    >
      { children }
    </PlanBenefitsContext.Provider>

  );
};

export default PlanBenefitsProvider;
