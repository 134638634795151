import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
} from '@material-ui/core';
import { useContext, useEffect } from 'react';
import LoadingList from 'src/components/LoadingList';
import DataNotFoundList from 'src/components/DataNotFoundList';
import { OfferContext } from 'src/context/OfferContext';
import OfferListResults from 'src/components/offer/OfferListResults';
import OfferListToolbar from 'src/components/offer/OfferListToolbar';

const OfferList = () => {
  const {
    offers,
    loading,
    getAllOffers,
    searchOffers,
    paginateOffer,
  } = useContext(OfferContext);
  useEffect(async () => {
    await getAllOffers();
  }, []);

  const render = () => {
    if (loading) {
      return (<LoadingList />);
    }

    if (offers && offers.data && offers.data.length > 0) {
      return (
        <OfferListResults offers={offers} paginate={paginateOffer} />
      );
    }
    return (<DataNotFoundList />);
  };

  return (
    <>
      <Helmet>
        <title>
          {`Marcas | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <OfferListToolbar search={searchOffers} />
          <Box sx={{ pt: 3 }}>
            {render()}
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default OfferList;
