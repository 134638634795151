import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
} from '@material-ui/core';
import PlanListResults from 'src/components/plan/PlanListResults';
import PlanListToolbar from 'src/components/plan/PlanListToolbar';
import { PlanContext } from 'src/context/PlanContext';
import { useContext, useEffect } from 'react';
import LoadingList from 'src/components/LoadingList';
import DataNotFoundList from 'src/components/DataNotFoundList';

const PlanList = () => {
  const {
    plans,
    loading,
    getPlans,
    deletePlan,
    searchPlans,
    paginatePlan,
  } = useContext(PlanContext);
  useEffect(async () => {
    await getPlans();
  }, []);

  const render = () => {
    if (loading) {
      return (<LoadingList />);
    }

    if (plans && plans.data && plans.data.length > 0) {
      return (
        <PlanListResults plans={plans} exclude={deletePlan} paginate={paginatePlan} />
      );
    }
    return (<DataNotFoundList />);
  };

  return (
    <>
      <Helmet>
        <title>
          {`Marcas | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <PlanListToolbar search={searchPlans} />
          <Box sx={{ pt: 3 }}>
            {render()}
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default PlanList;
