import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import {
  ThumbsUp as ThumbsUpIcon,
  ThumbsDown as ThumbsDownIcon,
  Edit as EditIcon,
  Trash2 as DeleteIcon,
} from 'react-feather';
import { useNavigate } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import DeleteModal from 'src/components/modal/DeleteModal';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
});

const ClientListResults = ({
  clients,
  exclude,
  paginate,
}) => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const classes = useStyles();
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: null,
    text: 'Tem certeza que deseja excluir este Cliente ?',
  });

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    paginate(newPage + 1);
  };

  const buttonEdit = (id) => {
    navigate(`/app/client/${id}`, { replace: true });
  };

  const excludeClient = async (id) => {
    const confirm = await exclude(id);
    if (confirm) {
      setDeleteModal({
        ...deleteModal,
        open: false
      });
    }
  };

  const matches = useMediaQuery('(min-width:700px)');

  const deleteClient = (id) => {
    setDeleteModal({
      ...deleteModal,
      id,
      open: true,
      confirm: excludeClient,
      cancel: () => { }
    });
  };

  const editButton = (id) => (
    <Button
      onClick={() => { buttonEdit(id); }}
      title="Editar"
      fullWidth
      variant="contained"
      color="primary"
    >
      <EditIcon />
    </Button>
  );

  const deleteButton = (id) => (
    <Button
      onClick={() => { deleteClient(id); }}
      title="Excluir"
      fullWidth
      variant="contained"
      color="error"
    >
      <DeleteIcon />
    </Button>
  );

  const listCard = (data, slice, classe) => (
    data.data.slice(0, slice).map((client) => (
      <Grid key={client.id} item xs={12} md={3} lg={3}>
        <Card className={classe.root}>
          <CardActionArea>
            <CardMedia
              component="img"
              alt={client.name}
              height="140"
              image={client.logo ? client.logo : '/image/no-image.png'}
              title={client.name}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {client.name}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            {editButton()}
            {deleteButton()}
          </CardActions>
        </Card>
      </Grid>
    ))
  );

  const listTable = (data, slice) => (
    <Card style={{ marginLeft: '25px', marginTop: '25px', width: '100%' }}>
      <CardHeader title="Clientes" />
      <Divider />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Logo
                </TableCell>
                <TableCell>
                  Nome Fantasia
                </TableCell>
                <TableCell>
                  Email
                </TableCell>
                <TableCell>
                  Lojas
                </TableCell>
                <TableCell>
                  Produtos
                </TableCell>
                <TableCell>
                  Ativo
                </TableCell>
                <TableCell>
                  Editar
                </TableCell>
                <TableCell>
                  Excluir
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { data.data.slice(0, slice).map((client) => (
                <TableRow
                  hover
                  key={client.id}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Avatar
                        src={client.logo !== '' ? client.logo : `${process.env.REACT_APP_URL}/images/no-image.png`}
                        sx={{ mr: 2 }}
                      >
                        {client.name}
                      </Avatar>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {client.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {client.name}
                  </TableCell>
                  <TableCell>
                    {client.email}
                  </TableCell>
                  <TableCell>
                    {client.branchs}
                  </TableCell>
                  <TableCell>
                    {client.products}
                  </TableCell>
                  <TableCell>
                    {client.active ? <ThumbsUpIcon color="#05ffa1" /> : <ThumbsDownIcon color="#e34234" />}
                  </TableCell>
                  <TableCell>
                    {editButton(client.id)}
                  </TableCell>
                  <TableCell>
                    {deleteButton(client.id)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );

  const renderList = (data, slice, classe) => {
    if (matches) {
      return listTable(data, slice, classe);
    }

    return listCard(data, slice, classe);
  };

  return (
    <>
      <Box sx={{ minWidth: 1050 }}>
        <Grid
          container
          spacing={3}
        >
          {renderList(clients, limit, classes, buttonEdit, deleteClient)}
        </Grid>
      </Box>
      <TablePagination
        component="div"
        count={clients.meta.total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={(clients.meta.current_page - 1)}
        rowsPerPage={limit}
        rowsPerPageOptions={[]}
      />
      <DeleteModal modal={deleteModal} />
    </>
  );
};

ClientListResults.propTypes = {
  clients: PropTypes.object.isRequired,
  exclude: PropTypes.func.isRequired,
  paginate: PropTypes.func.isRequired
};

export default ClientListResults;
