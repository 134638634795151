/* global google */
import { Button } from '@material-ui/core';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Edit as EditIcon } from 'react-feather';
import { useNavigate } from 'react-router';

const Maps = ({ branchs, draggable, returnCoord }) => {
  const navigate = useNavigate();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAPS_API,
  });
  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();
  const [currentMarkers, setCurrentMarkers] = useState([]);

  const onMapLoad = (map) => {
    setMapRef(map);
    const bounds = new google.maps.LatLngBounds();
    if (currentMarkers.length > 0) {
      currentMarkers.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
      map.fitBounds(bounds);
    }
  };

  const handleMarkerClick = (id, lat, lng, name, logo) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ id, name, logo });
    setIsOpen(true);
  };

  useEffect(() => {
    if (branchs.length > 0) {
      setCurrentMarkers(branchs);
      onMapLoad(mapRef);
    }
  }, [branchs]);

  useEffect(() => {
    if (currentMarkers.length > 0 && mapRef) {
      onMapLoad(mapRef);
    }
  }, [currentMarkers, mapRef]);

  const changeMarker = (coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();

    returnCoord(lat, lng);
  };

  const buttonEdit = (id) => {
    console.log(`/app/client/branch/${id}`);
    navigate(`/app/branch/${id}`, { replace: true });
  };

  return (
    <div>
      {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <GoogleMap
          mapContainerClassName="map-container"
          onLoad={onMapLoad}
          mapContainerStyle={{ width: '100vw', height: '100vh' }}
          onClick={() => setIsOpen(false)}
        >
          {currentMarkers.map(({
            id,
            name,
            lat,
            lng,
            logo
          }) => (
            <Marker
              key={id}
              position={{ lat, lng }}
              draggable={draggable}
              onClick={() => {
                handleMarkerClick(id, lat, lng, name, logo);
              }}
              onDragEnd={(coord) => {
                changeMarker(coord);
              }}
            >
              {isOpen && infoWindowData?.id === id && (
                <InfoWindow
                  onCloseClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <>
                    <center>
                      <h3>{infoWindowData.name}</h3>
                      <img width="100px" alt={infoWindowData.name} src={infoWindowData.logo} />
                      <Button
                        onClick={() => { buttonEdit(id); }}
                        title="Editar"
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        <EditIcon />
                      </Button>
                    </center>
                  </>
                </InfoWindow>
              )}
            </Marker>
          ))}
        </GoogleMap>
      )}
    </div>
  );
};

Maps.propTypes = {
  branchs: PropTypes.array,
  draggable: PropTypes.bool,
  returnCoord: PropTypes.func,
};

export default Maps;
