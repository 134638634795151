import { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import {
  Edit as EditIcon
} from 'react-feather';
import { useNavigate } from 'react-router';

const SubscriptionListResults = ({
  type,
  subscriptions,
  exclude,
  paginate,
  ...rest
}) => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
    paginate((newPage + 1));
  };

  const buttonEdit = (id) => {
    navigate(`/app/subscription/${id}`, { replace: true });
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Plano
                </TableCell>
                <TableCell>
                  Início
                </TableCell>
                <TableCell>
                  Final
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
                <TableCell>
                  Editar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptions.data.slice(0, limit).map((subscription) => (
                <TableRow
                  hover
                  key={subscription.id}
                >
                  <TableCell>
                    {subscription.plan.name}
                  </TableCell>
                  <TableCell>
                    {subscription.begin}
                  </TableCell>
                  <TableCell>
                    {subscription.end}
                  </TableCell>
                  <TableCell>
                    {subscription.statusDescription}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => { buttonEdit(subscription.id); }} variant="contained" color="primary">
                      <EditIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={subscriptions.meta.total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[]}
      />
    </Card>
  );
};

SubscriptionListResults.propTypes = {
  subscriptions: PropTypes.array.isRequired,
  exclude: PropTypes.array.isRequired,
  paginate: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};

export default SubscriptionListResults;
