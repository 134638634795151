import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Account from 'src/pages/Account';
import CustomerList from 'src/pages/CustomerList';
import ClientList from 'src/pages/ClientList';
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import Settings from 'src/pages/Settings';
import SiteProvider from './context/SiteContext';
import UserProvider from './context/UserContext';
import ClientProvider from './context/ClientContext';
import ClientForm from './pages/ClientForm';
import ProductProvider from './context/ProductContext';
import DocumentTypeProvider from './context/DocumentTypeContext';
import CategoryProvider from './context/CategoryContext';
import CategoryList from './pages/CategoryList';
import CategoryForm from './pages/CategoryForm';
import UserForm from './pages/UserForm';
import UserList from './pages/UserList';
import BranchProvider from './context/BranchContext';
import BranchForm from './pages/BranchForm';
import OfferProvider from './context/OfferContext';
import OfferMyProductProvider from './context/OfferMyProductContext';
import MyOfferForm from './pages/MyOfferForm';
import OfferMyProductForm from './pages/OfferMyProductForm';
import ProductForm from './pages/ProductForm';
import DashboardProvider from './context/DashboardContext';
import OfferList from './pages/OfferList';
import OfferForm from './pages/OfferForm';
import SubscriptionProvider from './context/SubscriptionContext';
import SubscriptionForm from './pages/SubscriptionForm';
import SubscriptionBillingProvider from './context/SubscriptionBillingContext';
import PlanProvider from './context/PlanContext';
import PlanList from './pages/PlanList';
import PlanForm from './pages/PlanForm';
import PlanBenefitsProvider from './context/PlanBenefitsContext';

const routes = [
  {
    path: 'app',
    element:
  <SiteProvider>
    <DocumentTypeProvider>
      <UserProvider>
        <ClientProvider>
          <ProductProvider>
            <CategoryProvider>
              <BranchProvider>
                <OfferProvider>
                  <OfferMyProductProvider>
                    <DashboardProvider>
                      <SubscriptionProvider>
                        <SubscriptionBillingProvider>
                          <PlanProvider>
                            <PlanBenefitsProvider>
                              <DashboardLayout />
                            </PlanBenefitsProvider>
                          </PlanProvider>
                        </SubscriptionBillingProvider>
                      </SubscriptionProvider>
                    </DashboardProvider>
                  </OfferMyProductProvider>
                </OfferProvider>
              </BranchProvider>
            </CategoryProvider>
          </ProductProvider>
        </ClientProvider>
      </UserProvider>
    </DocumentTypeProvider>
  </SiteProvider>,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'users', element: <UserList /> },
      { path: 'user/:id', element: <UserForm /> },
      { path: 'user/', element: <UserForm /> },
      { path: 'categories', element: <CategoryList /> },
      { path: 'category/:id', element: <CategoryForm /> },
      { path: 'category/', element: <CategoryForm /> },
      { path: 'plans', element: <PlanList /> },
      { path: 'plan/:id', element: <PlanForm /> },
      { path: 'plan', element: <PlanForm /> },
      { path: 'offers', element: <OfferList /> },
      { path: 'offer/:id', element: <OfferForm /> },
      { path: 'clients', element: <ClientList /> },
      { path: 'client/:id', element: <ClientForm /> },
      { path: 'client/', element: <ClientForm /> },
      { path: 'branch/:id', element: <BranchForm /> },
      { path: 'subscription/:id', element: <SubscriptionForm /> },
      { path: 'product/:branch/create', element: <ProductForm /> },
      { path: 'product/:id', element: <ProductForm /> },
      { path: 'branch/:client/create', element: <BranchForm /> },
      { path: 'myOffer/:client/:id', element: <MyOfferForm /> },
      { path: 'offerMyProduct/:id', element: <OfferMyProductForm /> },
      { path: 'customers', element: <CustomerList /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element:
  <SiteProvider>
    <UserProvider>
      <MainLayout />
    </UserProvider>
  </SiteProvider>,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'login/:expiration', element: <Login /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
