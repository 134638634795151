import {
  React,
  useState,
  createContext,
  useContext
} from 'react';
import api from 'src/services/api';
import { SiteContext } from './SiteContext';

export const OfferContext = createContext();

const OfferProvider = (prop) => {
  const baseUrl = 'admin/myOffer';
  const { children } = prop;
  const [offer, setOffer] = useState(null);
  const [offers, setOffers] = useState([]);
  const [offersSettings, setOfferSettings] = useState([

  ]);
  const [urlPaginate, setUrlPaginate] = useState();
  const {
    setNotifyMessage,
    notifyMessageSave,
    closeNotifyMessage,
    notifyMessageError,
    loading,
    setLoading
  } = useContext(SiteContext);
  const [search, setSearch] = useState();

  const getAllOffers = async () => {
    setLoading(true);
    await api.get('admin/offer/all')
      .then((response) => {
        setOffers(response.data);
        setOfferSettings(response.data.meta);
        setUrlPaginate(response.data.meta.path);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getOffers = async (client) => {
    setLoading(true);
    await api.get(`${baseUrl}/${client}/all`)
      .then((response) => {
        setOffers(response.data);
        setOfferSettings(response.data.meta);
        setUrlPaginate(response.data.meta.path);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getOffer = async (client, id) => {
    setLoading(true);
    await api.get(`${baseUrl}/${client}/get/${id}`)
      .then((response) => {
        setOffer(response.data.data);
        setLoading(false);
        return response.data.data;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const searchOffers = async (client, param) => {
    setLoading(true);
    if (!param) {
      await getOffers();
    } else {
      await api.post(`${baseUrl}/${client}/search`, { param })
        .then((response) => {
          setOffers(response.data);
          setUrlPaginate(response.data.meta.path);
          setSearch(param);
          setLoading(false);
        })
        .catch((err) => {
          setOffers([]);
          setLoading(false);
          console.log(err);
        });
    }
  };

  const paginateOffer = async (number) => {
    setLoading(true);
    if (search) {
      await api.post(`${urlPaginate}?page=${number}`, { search })
        .then((response) => {
          setOffers(response.data);
          setOfferSettings(response.data.meta);
          setLoading(false);
        })
        .catch((err) => {
          setOffers([]);
          setLoading(false);
          console.log(err);
        });
    } else {
      await api.get(`${urlPaginate}?page=${number}`)
        .then((response) => {
          setOffers(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setOffers([]);
          setLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <OfferContext.Provider
      value={{
        offer,
        offers,
        loading,
        urlPaginate,
        offersSettings,
        getAllOffers,
        getOffers,
        setOffer,
        paginateOffer,
        getOffer,
        setNotifyMessage,
        searchOffers,
        closeNotifyMessage,
        notifyMessageError,
        notifyMessageSave
      }}
    >
      { children }
    </OfferContext.Provider>

  );
};

export default OfferProvider;
