import {
  React,
  useState,
  createContext,
  useContext
} from 'react';
import api from 'src/services/api';
import { SiteContext } from './SiteContext';

export const OfferMyProductContext = createContext();

const OfferMyProductProvider = (prop) => {
  const baseUrl = 'admin/offerMyProduct';
  const { children } = prop;
  const [offerMyProduct, setOfferMyProduct] = useState([]);
  const [offerMyProducts, setOfferMyProducts] = useState([]);
  const [offerMyProductsSettings, setOfferMyProductSettings] = useState([

  ]);
  const [urlPaginate, setUrlPaginate] = useState();
  const {
    setNotifyMessage,
    notifyMessageSave,
    closeNotifyMessage,
    notifyMessageError,
    loading,
    setLoading
  } = useContext(SiteContext);
  const [search, setSearch] = useState();

  const getOfferMyProducts = async (client) => {
    setLoading(true);
    await api.get(`${baseUrl}/${client}/all`)
      .then((response) => {
        setOfferMyProducts(response.data);
        setOfferMyProductSettings(response.data.meta);
        setUrlPaginate(response.data.meta.path);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getOfferMyProduct = async (client, id) => {
    setLoading(true);
    await api.get(`${baseUrl}/${client}/get/${id}`)
      .then((response) => {
        setOfferMyProduct(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const searchOfferMyProducts = async (param) => {
    setLoading(true);
    if (!param) {
      await getOfferMyProducts();
    } else {
      await api.post(`${baseUrl}/search`, { param })
        .then((response) => {
          setOfferMyProducts(response.data);
          setUrlPaginate(response.data.meta.path);
          setSearch(param);
          setLoading(false);
        })
        .catch((err) => {
          setOfferMyProducts([]);
          setLoading(false);
          console.log(err);
        });
    }
  };

  const paginateOfferMyProduct = async (number) => {
    setLoading(true);
    if (search) {
      await api.post(`${urlPaginate}?page=${number}`, { search })
        .then((response) => {
          setOfferMyProducts(response.data);
          setOfferMyProductSettings(response.data.meta);
          setLoading(false);
        })
        .catch((err) => {
          setOfferMyProducts([]);
          setLoading(false);
          console.log(err);
        });
    } else {
      await api.get(`${urlPaginate}?page=${number}`)
        .then((response) => {
          setOfferMyProducts(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setOfferMyProducts([]);
          setLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <OfferMyProductContext.Provider
      value={{
        offerMyProducts,
        loading,
        urlPaginate,
        offerMyProduct,
        setOfferMyProduct,
        offerMyProductsSettings,
        getOfferMyProducts,
        getOfferMyProduct,
        paginateOfferMyProduct,
        setNotifyMessage,
        searchOfferMyProducts,
        closeNotifyMessage,
        notifyMessageError,
        notifyMessageSave
      }}
    >
      { children }
    </OfferMyProductContext.Provider>

  );
};

export default OfferMyProductProvider;
