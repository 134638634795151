import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import getInitials from 'src/utils/getInitials';
import InputMask from 'react-input-mask';
import moment from 'moment';

const UserProfileDetails = (props) => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    image: '',
    name: '',
    email: '',
    verified: '',
  });

  const [image, setImage] = useState(null);

  const {
    user,
    saveform,
    updateform,
  } = props;

  useEffect(() => {
    if (user) {
      setValues({
        image: user.image,
        name: user.name,
        email: user.email,
        verified: user.verified,
      });
    }
  }, [user]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeFile = (event) => {
    setImage(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      if (key !== 'image') {
        if (key === 'verified') {
          const date = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');
          formData.append(key, date);
        } else {
          formData.append(key, value);
        }
      }
    });

    if (image) {
      formData.append('image', image);
    }

    if (user) {
      await updateform(user.id, formData);
    } else {
      const save = await saveform(formData);
      if (save) {
        navigate('/app/users', { replace: true });
      }
    }
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit}
      {...props}
    >
      <Card>
        <CardHeader
          subheader="Dados da Marca"
          title="Perfil"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <Avatar
                sx={{ width: 100, height: 100 }}
                lg={{ width: 200, height: 200 }}
                src={values.image}
              >
                {getInitials(values.name)}
              </Avatar>
              <Typography>Imagem Atual</Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                name="image"
                onChange={handleChangeFile}
                type="file"
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nome"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="E-mail"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Senha"
                name="password"
                onChange={handleChange}
                required={user && user.id !== null ? 0 : 1}
                value={values.password}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={12}
              xs={12}
            >
              <InputMask
                mask="99/99/9999"
                value={values.verified}
                maskChar=" "
                onChange={handleChange}
              >
                {() => <TextField fullWidth name="verified" />}
              </InputMask>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            Salvar
          </Button>
        </Box>
      </Card>
    </form>
  );
};

UserProfileDetails.propTypes = {
  user: PropTypes.object,
  updateform: PropTypes.func.isRequired,
  saveform: PropTypes.func.isRequired,
};

export default UserProfileDetails;
