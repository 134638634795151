/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable jsx-a11y/alt-text */
import {
  Alert,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography
} from '@material-ui/core';

import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';

const OfferProfileDetails = ({ offer, ...rest }) => {
  const [currentOffer, setCurrentOffer] = useState({
    product: {
      name: '',
      brand: '',
      images: {
        image: ''
      },
    },
    messages: [],
    client: {
      name: '',
      logo: '',
      phone: '',
      email: '',
    },
    client_product: {
      name: '',
      logo: '',
      phone: '',
      email: '',
    },
    quantity: '',
    status: '',
    product_offer: [],
    amount_product_offer: '',
    amount_products: ''
  });

  useEffect(() => {
    if (offer) {
      setCurrentOffer(offer);
    }
  }, [offer]);

  const diffOffer = () => {
    if (offer.diff_offer.difference_to === 'no_difference') {
      return (
        <Alert style={{ justifyContent: 'center' }} severity="success">
          <Typography variant="h4" component="h2"> Oferta justa para os 2 lados</Typography>
        </Alert>
      );
    }
    return (
      <Alert style={{ justifyContent: 'center' }} severity="warning">
        <Typography variant="h4" component="h2">{offer.diff_offer.difference_to === 'client_product' ? 'Dono do Produto em desvantagem por' : 'Ofertante em desvantagem por'}</Typography>
        <Typography variant="h4" component="h2">{`R$ ${offer.diff_offer.amount}`}</Typography>
      </Alert>
    );
  };

  return (
    <form
      autoComplete="off"
      {...rest}
    >
      <Card>
        <CardHeader
          title="Dados da Oferta"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >

            <Grid
              item
              md={6}
              lg={6}
              xs={6}
            />
            <Grid
              item
              md={12}
              lg={12}
              xs={12}
            >
              <Alert severity="info">{`Status ${offer.status}`}</Alert>
            </Grid>

            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <Typography variant="h4" component="h2">Dodo do Produto</Typography>
              <Paper elevation={3}>
                <center>
                  <img width={150} src={currentOffer.client_product.logo ? currentOffer.client_product.logo : `${process.env.REACT_APP_URL}/static/images/no-image.png`} />
                </center>
              </Paper>
              <div style={{ padding: '10px' }}>
                <Typography sx={{ fontWeight: 'bold' }}>Nome</Typography>
                <Typography>{currentOffer.client_product.name}</Typography>
                <Divider />
                <Typography sx={{ fontWeight: 'bold' }}>Telefone</Typography>
                <Typography>{currentOffer.client_product.phone}</Typography>
                <Divider />
                <Typography sx={{ fontWeight: 'bold' }}>E-mail</Typography>
                <Typography>{currentOffer.client_product.email}</Typography>
                <Divider />
              </div>
            </Grid>

            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <Typography variant="h4" component="h2">Produto</Typography>
              <Paper elevation={3}>
                <center>
                  <img width={150} src={currentOffer.product.images ? currentOffer.product.images.image : `${process.env.REACT_APP_URL}/static/images/no-image.png`} />
                </center>
              </Paper>
              <div style={{ padding: '10px' }}>
                <Typography sx={{ fontWeight: 'bold' }}>Nome</Typography>
                <Typography>{currentOffer.product.name}</Typography>
                <Divider />
                <Typography sx={{ fontWeight: 'bold' }}>Marca</Typography>
                <Typography>{currentOffer.product.brand}</Typography>
                <Divider />
                <Typography sx={{ fontWeight: 'bold' }}>Quantia</Typography>
                <Typography>{currentOffer.quantity}</Typography>
                <Divider />
                <Typography sx={{ fontWeight: 'bold' }}>Valor Unitário</Typography>
                <Typography>{`R$ ${currentOffer.product.value}`}</Typography>
              </div>
            </Grid>

            <Grid
              item
              md={12}
            >
              <Divider sx={
                  {
                    borderBottomWidth: 5
                  }
                }
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <Typography variant="h4" component="h2">Ofertante</Typography>
              <Paper elevation={3}>
                <center>
                  <img width={150} src={currentOffer.client.logo ? currentOffer.client.logo : `${process.env.REACT_APP_URL}/static/images/no-image.png`} />
                </center>
              </Paper>
              <div style={{ padding: '10px' }}>
                <Typography sx={{ fontWeight: 'bold' }}>Nome</Typography>
                <Typography>{currentOffer.client.name}</Typography>
                <Divider />
                <Typography sx={{ fontWeight: 'bold' }}>Telefone</Typography>
                <Typography>{currentOffer.client.phone}</Typography>
                <Divider />
                <Typography sx={{ fontWeight: 'bold' }}>E-mail</Typography>
                <Typography>{currentOffer.client.email}</Typography>
                <Divider />
              </div>
            </Grid>

            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <Alert icon={false} severity="info">
                <Typography variant="h4" component="h2">Produtos oferecidos</Typography>
              </Alert>
              <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <ListItem sx={{ borderBottom: 'dotted 1px silver' }}>
                  <ListItemText primary="Imagem" />
                  <ListItemText primary="Nome" />
                  <ListItemText primary="Marca" />
                  <ListItemText primary="Quantia" />
                  <ListItemText primary="Valor" />
                </ListItem>
                {currentOffer.product_offer.map((product) => (
                  <ListItem sx={{ borderBottom: 'solid 1px silver' }}>
                    <ListItemAvatar>
                      <Avatar src={product.images ? product.images.image : `${process.env.REACT_APP_URL}/static/images/no-image.png`} />
                    </ListItemAvatar>
                    <ListItemText primary={product.product.name} />
                    <ListItemText primary={product.product.brand} />
                    <ListItemText primary={product.product.quantity} />
                    <ListItemText primary={product.product.value} />
                  </ListItem>
                ))}
              </List>
            </Grid>

            <Grid
              item
              md={12}
              lg={12}
              xs={12}
            >
              <Alert icon={false} severity="info">
                <Typography variant="h4" component="h2">Valores</Typography>
              </Alert>
            </Grid>
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <Alert icon={false} severity="success">
                <Typography variant="h4" component="h2">Valor Total Produto</Typography>
                <Typography variant="h4" component="h2">{`R$ ${offer.amount_product_offer}`}</Typography>
              </Alert>
            </Grid>
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <Alert icon={false} severity="success">
                <Typography variant="h4" component="h2">Valor Total Produtos Oferecidos</Typography>
                <Typography variant="h4" component="h2">{`R$ ${offer.amount_products}`}</Typography>
              </Alert>
            </Grid>
            <Grid
              item
              md={12}
              lg={12}
              xs={12}
            >
              <Grid
                item
                md={6}
                lg={6}
                xs={12}
                sx={{ margin: 'auto' }}
              >
                {diffOffer()}
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              lg={12}
              xs={12}
            >
              <Alert icon={false} severity="info">
                <Typography variant="h4" component="h2">Conversas</Typography>
              </Alert>
              <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {currentOffer.messages.map((message) => (
                  <>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt={message.client.name} src={message.client.logo ? message.client.logo : `${process.env.REACT_APP_URL}/static/images/no-image.png`} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={message.client.name}
                        secondary={
                          <Fragment>
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {message.name}
                            </Typography>
                            {message.message}
                            <Typography style={{ fontSize: '12px' }}>{message.date}</Typography>
                          </Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </>
                ))}
              </List>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
      </Card>
    </form>
  );
};

OfferProfileDetails.propTypes = {
  offer: PropTypes.object
};

export default OfferProfileDetails;
