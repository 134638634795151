import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Trash2 as DeleteIcon,
} from 'react-feather';
import CurrencyFormat from 'react-currency-format';
import InputMask from 'react-input-mask';
import { toNumber } from 'lodash';
import DeleteModal from '../modal/DeleteModal';

const SubscriptionBillingListResults = ({
  subscriptionId,
  subscriptionBillings,
  subscriptionBillingsSettings,
  saveform,
  updateform,
  getForm,
  exclude,
  paginate,
  ...rest
}) => {
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(0);
  const [reasonError, setReasonError] = useState(false);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    id: 0,
    value: '',
    due_date: '',
    payment_date: '',
    status: '',
    reason: '',
  });
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: null,
    text: 'Tem certeza que deseja excluir esta Cobrança ?',
  });

  useEffect(() => {
    if (subscriptionBillingsSettings) {
      if (subscriptionBillingsSettings.current_page === 1) {
        setPage(0);
      } else {
        setPage(toNumber(subscriptionBillingsSettings.current_page) - 1);
      }
    }
  },
  [subscriptionBillingsSettings]);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
    paginate((newPage + 1));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const buttonEdit = async (id) => {
    const billing = await getForm(id);
    setValues({
      id: toNumber(id),
      value: billing.value,
      due_date: billing.due_date,
      payment_date: billing.payment_date,
      status: billing.status,
      reason: billing.reason,
    });
    handleOpen();
  };

  const buttonNew = () => {
    setValues({
      id: toNumber(0),
      value: '',
      due_date: '',
      payment_date: '',
      status: '',
      reason: '',
    });
    handleOpen();
  };

  const formatMoney = (value) => {
    let valueUnformat = value.replace('R$', '').replace(',', '').replace('.', '');
    valueUnformat = parseInt(valueUnformat, 10) / 100;
    return valueUnformat;
  };

  const formatDate = (date) => {
    const [day, month, year] = date.split('/');
    return `${year}-${month}-${day}`;
  };
  const validateUpdate = () => {
    if (values.status === 'D' && values.reason === '') {
      setReasonError(true);
      return false;
    }
    return true;
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'D':
        return '#d3d3d3';
      case 'P':
        return '#90EE90';
      case 'L':
        return '#FFD580';
      default:
        return '#ADD8E6';
    }
  };

  const formatForm = () => {
    values.subscription_id = subscriptionId;
    values.value = values.value ? formatMoney(values.value) : 0;
    values.due_date = values.due_date ? formatDate(values.due_date) : null;
    values.payment_date = values.payment_date ? formatDate(values.payment_date) : null;
  };

  const saveBilling = async () => {
    if (values.id === 0) {
      formatForm();
      await saveform(values);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (validateUpdate()) {
        formatForm();
        await updateform(values, values.id);
      }
    }
  };

  const excludeBilling = async (id) => {
    const confirm = await exclude(id, subscriptionId);
    if (confirm) {
      setDeleteModal({
        ...deleteModal,
        open: false
      });
    }
  };

  const deleteBilling = (id) => {
    setDeleteModal({
      ...deleteModal,
      id,
      open: true,
      confirm: excludeBilling,
      cancel: () => { }
    });
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Pagamento de Assinatura
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box sx={{ m: 1 }} />
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                lg={12}
                xs={12}
              >
                <InputMask
                  mask="99/99/9999"
                  value={values.due_date}
                  placeholder="DD/MM/YYYY"
                  onChange={handleChange}
                >
                  {() => <TextField fullWidth label="Data de Vencimento" name="due_date" />}
                </InputMask>
              </Grid>
              <Grid
                item
                md={12}
                lg={12}
                xs={12}
              >
                <CurrencyFormat
                  name="value"
                  customInput={TextField}
                  onChange={handleChange}
                  label="Valor"
                  fullWidth
                  decimalScale={2}
                  decimalSeparator=","
                  fixedDecimalScale
                  placeholder="R$ 0,00"
                  prefix="R$ "
                  value={values.value}
                  thousandSeparator="."
                />
              </Grid>
              <Grid
                item
                md={12}
                lg={12}
                xs={12}
              >
                <InputMask
                  mask="99/99/9999"
                  value={values.payment_date}
                  placeholder="DD/MM/YYYY"
                  onChange={handleChange}
                >
                  {() => <TextField fullWidth label="Data de pagamento" name="payment_date" />}
                </InputMask>
              </Grid>
              <Grid
                item
                md={12}
                lg={12}
                xs={12}
              >
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    name="status"
                    value={values.status}
                    onChange={handleChange}
                  >
                    <MenuItem value="W">Aguardando</MenuItem>
                    <MenuItem value="P">Pago</MenuItem>
                    <MenuItem value="L">Atrazado</MenuItem>
                    <MenuItem value="D">Baixado</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {values.status === 'D' ? (
                <Grid
                  item
                  md={12}
                  lg={12}
                  xs={12}
                >
                  <TextField
                    multiline
                    rows={3}
                    fullWidth
                    label="Motivo"
                    name="reason"
                    onChange={handleChange}
                    required
                    value={values.reason}
                    variant="outlined"
                  />
                  {reasonError ? (
                    <Grid>
                      <Box sx={{ m: 1 }} />
                      <Typography variant="span" style={{ color: 'red' }}> Preencha o motivo da baixa para salvar</Typography>
                    </Grid>
                  ) : null}
                </Grid>
              ) : null }
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
          >
            Fechar
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={saveBilling}
            autoFocus
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      <Card {...rest}>
        <Box
          sx={{
            display: 'flex',
            marginBottom: '10px',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={buttonNew}
          >
            Novo
          </Button>
        </Box>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Data de Vencimento
                  </TableCell>
                  <TableCell>
                    Data de Pagamento
                  </TableCell>
                  <TableCell>
                    Valor
                  </TableCell>
                  <TableCell>
                    Status
                  </TableCell>
                  <TableCell>
                    User
                  </TableCell>
                  <TableCell>
                    Editar
                  </TableCell>
                  <TableCell>
                    Excluir
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscriptionBillings.data.slice(0, limit).map((billing) => (
                  <TableRow
                    hover
                    key={billing.id}
                    style={{ backgroundColor: getStatusClass(billing.status) }}
                  >
                    <TableCell>
                      {billing.due_date}
                    </TableCell>
                    <TableCell>
                      {billing.payment_date}
                    </TableCell>
                    <TableCell>
                      {billing.value}
                    </TableCell>
                    <TableCell>
                      {billing.statusDescription}
                      <Typography variant="h5" style={{ fontStyle: 'italic' }}>
                        {billing.status === 'D' ? billing.user.name : ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {billing.user.name}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => { buttonEdit(billing.id); }}
                        variant="contained"
                        color="primary"
                      >
                        <EditIcon />
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => { deleteBilling(billing.id); }} variant="contained" color="error">
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={subscriptionBillingsSettings.total}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[]}
        />
        <DeleteModal modal={deleteModal} />
      </Card>
    </Grid>
  );
};

SubscriptionBillingListResults.propTypes = {
  subscriptionBillings: PropTypes.object.isRequired,
  exclude: PropTypes.func.isRequired,
  paginate: PropTypes.func.isRequired,
  subscriptionId: PropTypes.string.isRequired,
  getForm: PropTypes.func.isRequired,
  saveform: PropTypes.func.isRequired,
  updateform: PropTypes.func.isRequired,
  subscriptionBillingsSettings: PropTypes.object.isRequired,
};

export default SubscriptionBillingListResults;
