/* eslint-disable react/jsx-boolean-value */
import { useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router';
import getInitials from 'src/utils/getInitials';
import { SiteContext } from 'src/context/SiteContext';
import InputMask from 'react-input-mask';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import LoadMaps from '../maps/LoadMaps';

const BranchProfileDetails = (props) => {
  const { client } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { getAddressByZipCode } = useContext(SiteContext);
  const [values, setValues] = useState({
    image: '',
    fantasy_name: '',
    social_name: '',
    document_number: '',
    zip_code: '',
    state: '',
    city: '',
    district: '',
    street: '',
    number: '',
    complement: '',
    active: '',
    latitude: '',
    longitude: '',
  });

  const [currentMarker, setCurrentMarker] = useState([]);

  const [image, setImage] = useState(null);

  const {
    branch,
    saveform,
    updateform,
    getGeoLocation
  } = props;

  useEffect(() => {
    if (branch) {
      setValues({
        image: branch.logo,
        fantasy_name: branch.fantasy_name,
        social_name: branch.social_name,
        document_number: branch.document_number,
        zip_code: branch.zip_code,
        state: branch.state,
        city: branch.city,
        district: branch.district,
        street: branch.street,
        number: branch.number,
        complement: branch.complement,
        active: branch.active,
        latitude: branch.latitude,
        longitude: branch.longitude
      });

      const markerBranch = [{
        id: branch.id,
        name: branch.fantasy_name,
        logo: branch.logo !== '' ? branch.logo : `${process.env.REACT_APP_URL}/images/no-image.png`,
        lat: branch.latitude,
        lng: branch.longitude
      }];
      setCurrentMarker(markerBranch);
    }
  }, [branch]);

  const handleChange = (event) => {
    if (event.target.name === 'active') {
      setValues({
        ...values,
        [event.target.name]: event.target.checked ? 1 : 0
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    }
  };

  const handleChangeFile = (event) => {
    setImage(event.target.files[0]);
  };

  const zipCodeChange = async (e) => {
    let zipCode = e.target.value.replace('-', '');
    zipCode = zipCode.trim();
    if (zipCode.length === 8) {
      const address = await getAddressByZipCode(zipCode);
      if (address) {
        setValues({
          ...values,
          zip_code: address.data.cep,
          state: address.data.uf,
          city: address.data.localidade,
          district: address.data.bairro,
          street: address.data.logradouro
        });
      }
    }
  };

  const submitForm = async () => {
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    if (image) {
      formData.append('logo', image);
    }

    if (branch) {
      await updateform(branch.id, formData);
    } else {
      formData.append('client_id', client);
      const save = await saveform(formData);
      if (save) {
        navigate(`/app/client/${client}`, { replace: true });
      }
    }
  };

  const changeGeo = () => {
    if (branch.latitude !== values.latitude
      && branch.longitude !== values.longitude
    ) {
      setOpen(true);
    } else {
      submitForm();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    changeGeo();
  };

  const getLatLong = (lat, lng) => {
    setValues({
      ...values,
      latitude: lat,
      longitude: lng,
    });
    const markerBranch = [{
      id: branch.id,
      name: branch.fantasy_name,
      logo: branch.logo !== '' ? branch.logo : `${process.env.REACT_APP_URL}/images/no-image.png`,
      lat,
      lng
    }];
    setCurrentMarker(markerBranch);
  };

  const handleConfirm = () => {
    submitForm();
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
      delete values.latitude;
      delete values.longitude;
      submitForm();
    }
  };

  const getLatLongAddress = async () => {
    const response = await getGeoLocation(values.state, values.street, values.zip_code, values.district, values.number);
    getLatLong(response.lat, response.lng);
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit}
      {...props}
    >
      <Card>
        <CardHeader
          subheader="Dados da Marca"
          title="Perfil"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <Avatar
                sx={{ width: 100, height: 100 }}
                lg={{ width: 200, height: 200 }}
                src={values.image}
              >
                {getInitials(values.name)}
              </Avatar>
              <Typography>Imagem Atual</Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                name="image"
                onChange={handleChangeFile}
                type="file"
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nome Fantasia"
                name="fantasy_name"
                onChange={handleChange}
                required
                value={values.fantasy_name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nome Social"
                name="social_name"
                onChange={handleChange}
                required
                value={values.social_name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Numero do Documento"
                name="document_number"
                onChange={handleChange}
                required
                value={values.document_number}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <Typography variant="h1" component="h2">
                Endereço
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <InputMask
                mask="99999-999"
                value={values.zip_code}
                maskChar=" "
                onChange={(e) => { handleChange(e); zipCodeChange(e); }}
              >
                {() => <TextField fullWidth name="zip_code" />}
              </InputMask>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Estado"
                name="state"
                onChange={handleChange}
                value={values.state}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Cidade"
                name="city"
                onChange={handleChange}
                value={values.city}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Bairro"
                name="district"
                onChange={handleChange}
                value={values.district}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Rua"
                name="street"
                onChange={handleChange}
                value={values.street}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Numero"
                name="number"
                onChange={handleChange}
                value={values.number}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Complemento"
                name="complement"
                onChange={handleChange}
                value={values.complement}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <Tooltip title="Procurar Localização" placement="top-start">
                <Button color="primary" variant="contained" onClick={() => { getLatLongAddress(); }}><PersonPinCircleIcon /></Button>
              </Tooltip>
            </Grid>

            <Grid
              item
              md={12}
              lg={12}
              xs={12}
            >
              <LoadMaps markers={currentMarker} draggable={true} returnCoord={(lat, lng) => { getLatLong(lat, lng); }} />
            </Grid>
            <Grid
              item
              md={6}
              lg={12}
              xs={12}
            >
              Ativo
              <Switch
                checked={values.active}
                name="active"
                color="success"
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            Salvar
          </Button>
        </Box>
        <Dialog fullWidth onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Atenção
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              O marcador da localização no mapa foi alterado, você deseja confirma esta nova localização ?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose} color="inherit">
              Reverter
            </Button>
            <Button variant="contained" onClick={handleConfirm} color="primary">
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </form>
  );
};

BranchProfileDetails.propTypes = {
  branch: PropTypes.object,
  updateform: PropTypes.func.isRequired,
  getBranch: PropTypes.func.isRequired,
  saveform: PropTypes.func.isRequired,
  getGeoLocation: PropTypes.func.isRequired,
};

export default BranchProfileDetails;
