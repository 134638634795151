import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
} from '@material-ui/core';
import CategoryListResults from 'src/components/category/CategoryListResults';
import CategoryListToolbar from 'src/components/category/CategoryListToolbar';
import { CategoryContext } from 'src/context/CategoryContext';
import { useContext, useEffect } from 'react';
import LoadingList from 'src/components/LoadingList';
import DataNotFoundList from 'src/components/DataNotFoundList';

const CategoryList = () => {
  const {
    categories,
    loading,
    getCategorys,
    deleteCategory,
    searchCategorys,
    paginateCategory,
  } = useContext(CategoryContext);
  useEffect(async () => {
    await getCategorys();
  }, []);

  const render = () => {
    if (loading) {
      return (<LoadingList />);
    }

    if (categories && categories.data && categories.data.length > 0) {
      return (
        <CategoryListResults categories={categories} exclude={deleteCategory} paginate={paginateCategory} />
      );
    }
    return (<DataNotFoundList />);
  };

  return (
    <>
      <Helmet>
        <title>
          {`Marcas | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <CategoryListToolbar search={searchCategorys} />
          <Box sx={{ pt: 3 }}>
            {render()}
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default CategoryList;
