import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import ProductListResults from 'src/components/product/ProductListResults';
import ProductListToolbar from 'src/components/product/ProductListToolbar';
import { ProductContext } from 'src/context/ProductContext';
import { useContext } from 'react';
import LoadingList from 'src/components/LoadingList';
import DataNotFoundList from 'src/components/DataNotFoundList';

const ProductList = ({ products }) => {
  const {
    loading,
    deleteProduct,
    searchProducts,
    paginateProduct,
  } = useContext(ProductContext);

  const render = () => {
    if (loading) {
      return (<LoadingList />);
    }

    if (products && products.data && products.data.length > 0) {
      return (
        <ProductListResults products={products} exclude={deleteProduct} paginate={paginateProduct} />
      );
    }
    return (<DataNotFoundList />);
  };

  return (
    <>
      <ProductListToolbar search={searchProducts} />
      <Box sx={{ pt: 3 }}>
        {render()}
      </Box>
    </>
  );
};

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default ProductList;
