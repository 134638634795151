import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

const SubscriptionBillingLate = (props) => {
  const navigate = useNavigate();
  const { billings } = props;
  const seeSubscription = (subscriptionId) => {
    navigate(`/app/subscription/${subscriptionId}`, { replace: true });
  };

  return (
    <Card {...props}>
      <CardHeader title="Pagamentos Atrasados" />
      <Divider />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Cliente
                </TableCell>
                <TableCell>
                  Plano
                </TableCell>
                <TableCell>
                  Valor
                </TableCell>
                <TableCell>
                  Vencimento
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
                <TableCell>
                  Visualizar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {billings.map((billing) => (
                <TableRow
                  hover
                  key={billing.id}
                >
                  <TableCell>
                    {billing.subscription.client.name}
                  </TableCell>
                  <TableCell>
                    {billing.subscription.plan.name}
                  </TableCell>
                  <TableCell>
                    {billing.value}
                  </TableCell>
                  <TableCell>
                    {moment(billing.due_date).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    <Chip
                      color="primary"
                      label={billing.statusDescription}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      edge="end"
                      size="small"
                    >
                      <VisibilityIcon onClick={() => { seeSubscription(billing.subscription_id); }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button
          sx={{ display: 'none' }}
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          View all
        </Button>
      </Box>
    </Card>
  );
};

SubscriptionBillingLate.propTypes = {
  billings: PropTypes.array,
};

export default SubscriptionBillingLate;
