/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router';
import getInitials from 'src/utils/getInitials';
import CurrencyFormat from 'react-currency-format';
import InputMask from 'react-input-mask';

const ProductProfileDetails = (props) => {
  const { branch } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    image: '',
    name: '',
    brand: '',
    quantity: '',
    validity: '',
    no_expiration: '',
    value: '',
  });

  const [image, setImage] = useState(null);

  const {
    product,
    saveform,
    updateform,
  } = props;

  useEffect(() => {
    if (product) {
      setValues({
        image: product.images ? product.images.image : null,
        name: product.name,
        brand: product.brand,
        quantity: product.quantity,
        no_expiration: product.no_expiration,
        validity: product.validity ? product.validity : undefined,
        value: product.value,
      });

      if (product.image) {
        setImage(product.image);
      }
    }
  }, [product]);

  const handleChange = (event) => {
    if (event.target.name === 'no_expiration') {
      setValues({
        ...values,
        [event.target.name]: event.target.checked ? 1 : 0
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    }
  };

  const handleChangeFile = (event) => {
    setImage(event.target.files[0]);
    setValues({
      ...values,
      image: URL.createObjectURL(event.target.files[0])
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      if (key !== 'image' && value !== undefined) {
        formData.append(key, value);
      }
    });

    if (image) {
      formData.append('images[]', image);
    }

    if (product) {
      await updateform(product.id, formData);
    } else {
      formData.append('branch_id', branch);
      const save = await saveform(formData);
      if (save) {
        navigate(`/app/branch/${branch}`, { replace: true });
      }
    }
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit}
      {...props}
    >
      <Card>
        <CardHeader
          subheader="Dados do Produto"
          title="Produto"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <Avatar
                sx={{ width: 100, height: 100 }}
                lg={{ width: 200, height: 200 }}
                src={values.image !== '' ? values.image : `${process.env.REACT_APP_URL}/images/no-image.png`}
              >
                {getInitials(values.name)}
              </Avatar>
              <Typography>Imagem Atual</Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                name="image"
                onChange={handleChangeFile}
                type="file"
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nome"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Marca"
                name="brand"
                onChange={handleChange}
                required
                value={values.brand}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Quantia"
                name="quantity"
                onChange={handleChange}
                required
                value={values.quantity}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <CurrencyFormat
                name="value"
                customInput={TextField}
                onChange={handleChange}
                label="Valor"
                fullWidth
                decimalScale={2}
                decimalSeparator=","
                fixedDecimalScale
                placeholder="R$ 0,00"
                prefix="R$ "
                value={values.value}
                thousandSeparator="."
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={12}
              xs={12}
            >
              Não Controla Validade
              <Switch
                checked={values.no_expiration}
                name="no_expiration"
                color="success"
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            {!values.no_expiration ? (
              <>
                <Grid
                  sty
                  item
                  md={6}
                  xs={12}
                >
                  <InputMask
                    mask="99/99/9999"
                    value={values.validity}
                    placeholder="DD/MM/YYYY"
                    onChange={handleChange}
                  >
                    {() => <TextField fullWidth label="Data de validade" name="validity" />}
                  </InputMask>
                </Grid>
              </>
            )
              : <></>}
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            Salvar
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ProductProfileDetails.propTypes = {
  product: PropTypes.object,
  updateform: PropTypes.func.isRequired,
  getProduct: PropTypes.func.isRequired,
  saveform: PropTypes.func.isRequired,
};

export default ProductProfileDetails;
