import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export default function DeleteModal({ modal }) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (modal) {
      setOpen(modal.open);
    }
  }, [modal]);

  const handleConfirm = () => {
    modal.confirm(modal.id);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
      modal.cancel();
    }
  };

  return (
    <div>
      <Dialog fullWidth onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Exclusão de Registro
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {modal.text}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="inherit">
            Cancelar
          </Button>
          <Button variant="contained" onClick={handleConfirm} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DeleteModal.propTypes = {
  modal: PropTypes.object
};
