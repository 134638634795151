/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

const SubscriptionProfileDetails = (props) => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    plan: '',
    begin: '',
    end: '',
    status: '',
    reason: '',
  });

  const [image, setImage] = useState(null);

  const {
    subscription,
    saveform,
    updateform,
  } = props;

  useEffect(() => {
    if (subscription) {
      setValues({
        plan: subscription.plan,
        begin: subscription.begin,
        end: subscription.end,
        status: subscription.status,
        reason: subscription.reason,
      });

      if (subscription.image) {
        setImage(subscription.image);
      }
    }
  }, [subscription]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      if (key !== 'image' && value !== undefined) {
        formData.append(key, value);
      }
    });

    if (image) {
      formData.append('images[]', image);
    }

    if (subscription) {
      await updateform(subscription.id, formData);
    } else {
      const save = await saveform(formData);
      if (save) {
        navigate(`/app/client/${subscription.client_id}`, { replace: true });
      }
    }
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit}
      {...props}
    >
      <Card>
        <CardHeader
          subheader="Dados do Plano"
          title="Plano"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              lg={12}
              xs={12}
            >
              <TextField
                disabled
                fullWidth
                label="Plano"
                name="name"
                onChange={handleChange}
                required
                value={values.plan.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              lg={3}
              xs={12}
            >
              <TextField
                disabled
                fullWidth
                label="Valor"
                name="brand"
                onChange={handleChange}
                required
                value={values.plan.value}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              lg={3}
              xs={12}
            >
              <TextField
                disabled
                fullWidth
                label="Tipo de Recorrencia"
                name="quantity"
                onChange={handleChange}
                required
                value={values.plan.type_recurrence}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              lg={3}
              xs={12}
            >
              <TextField
                disabled
                fullWidth
                label="Quantia de recorrencias"
                name="quantity"
                onChange={handleChange}
                required
                value={values.plan.total_charges}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              lg={3}
              xs={12}
            >
              Cortesia
              <Switch
                disabled
                checked={values.plan.courtesy}
                name="no_expiration"
                color="success"
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
      </Card>
      <Card>
        <CardHeader
          subheader="Dados da Assinatura"
          title="Assinatura"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={4}
              lg={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="Início"
                name="begin"
                onChange={handleChange}
                required
                value={values.begin}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={4}
              lg={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="Final"
                name="end"
                onChange={handleChange}
                required
                value={values.end}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={4}
              lg={4}
              xs={12}
            >
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  name="status"
                  value={values.status}
                  onChange={handleChange}
                >
                  <MenuItem value="A">Ativo</MenuItem>
                  <MenuItem value="E">Finalizado</MenuItem>
                  <MenuItem value="L">Atrazado</MenuItem>
                  <MenuItem value="C">Cancelado</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={12}
              lg={12}
              xs={12}
            >
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Motivo"
                name="quantity"
                onChange={handleChange}
                required
                value={values.reason}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            Salvar
          </Button>
        </Box>
      </Card>
    </form>
  );
};

SubscriptionProfileDetails.propTypes = {
  subscription: PropTypes.object,
  updateform: PropTypes.func.isRequired,
  saveform: PropTypes.func.isRequired,
};

export default SubscriptionProfileDetails;
