import {
  React,
  useState,
  createContext,
  useContext
} from 'react';
import api from 'src/services/api';
import { SiteContext } from './SiteContext';

export const BranchContext = createContext();

const BranchProvider = (prop) => {
  const baseUrl = 'admin/branch';
  const { children } = prop;
  const [branch, setBranch] = useState(null);
  const [branchs, setBranchs] = useState([]);
  const [branchsSettings, setBranchSettings] = useState([

  ]);
  const [urlPaginate, setUrlPaginate] = useState();
  const {
    setNotifyMessage,
    notifyMessageSave,
    closeNotifyMessage,
    notifyMessageError,
    loading,
    setLoading
  } = useContext(SiteContext);
  const [search, setSearch] = useState();

  const getAllBranchs = async () => {
    setLoading(true);
    await api.get(`${baseUrl}/notPaginate`)
      .then((response) => {
        setBranchs(response.data);
        setBranchSettings(response.data.meta);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getBranchs = async (client) => {
    setLoading(true);
    await api.get(`${baseUrl}/${client}/all`)
      .then((response) => {
        setBranchs(response.data);
        setBranchSettings(response.data.meta);
        setUrlPaginate(response.data.meta.path);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getBranch = async (id) => {
    setLoading(true);
    await api.get(`${baseUrl}/get/${id}`)
      .then((response) => {
        setBranch(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const searchBranchs = async (param) => {
    setLoading(true);
    if (!param) {
      await getBranchs();
    } else {
      await api.post(`${baseUrl}/search`, { param })
        .then((response) => {
          setBranchs(response.data);
          setUrlPaginate(response.data.meta.path);
          setSearch(param);
          setLoading(false);
        })
        .catch((err) => {
          setBranchs([]);
          setLoading(false);
          console.log(err);
        });
    }
  };

  const saveBranch = async (data) => {
    setLoading(true);
    const serverResponse = await api.post(`${baseUrl}/create`, data)
      .then((response) => {
        setBranch(response.data.data);
        setLoading(false);
        notifyMessageSave('Salvo');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
    return serverResponse;
  };

  const updateBranch = async (id, data) => {
    setLoading(true);
    await api.post(`${baseUrl}/update/${id}`, data)
      .then((response) => {
        setBranch(response.data.data);
        notifyMessageSave('Atualizado');
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const paginateBranch = async (number) => {
    setLoading(true);
    if (search) {
      await api.post(`${urlPaginate}?page=${number}`, { search })
        .then((response) => {
          setBranchs(response.data);
          setBranchSettings(response.data.meta);
          setLoading(false);
        })
        .catch((err) => {
          setBranchs([]);
          setLoading(false);
          console.log(err);
        });
    } else {
      await api.get(`${urlPaginate}?page=${number}`)
        .then((response) => {
          setBranchs(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setBranchs([]);
          setLoading(false);
          console.log(err);
        });
    }
  };

  const deleteBranch = async (id) => {
    setLoading(true);
    const response = await api.delete(`${baseUrl}/delete/${id}`)
      .then(() => {
        setLoading(false);
        notifyMessageSave('excluido');
        getBranchs();
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
    return response;
  };

  const getGeoLocation = async (state, street, zipCode, district, number) => {
    console.log(state, street, zipCode, district, number);
    const responseData = await api.post(`${baseUrl}/getGeo`,
      {
        state,
        street,
        zipCode,
        district,
        number
      })
      .then((response) => (
        response.data
      ))
      .catch((err) => {
        console.log(err);
        return false;
      });

    return responseData;
  };

  return (
    <BranchContext.Provider
      value={{
        branch,
        branchs,
        loading,
        urlPaginate,
        branchsSettings,
        getBranchs,
        getAllBranchs,
        saveBranch,
        updateBranch,
        setBranch,
        deleteBranch,
        paginateBranch,
        getGeoLocation,
        getBranch,
        setNotifyMessage,
        searchBranchs,
        closeNotifyMessage,
        notifyMessageError,
        notifyMessageSave
      }}
    >
      { children }
    </BranchContext.Provider>

  );
};

export default BranchProvider;
