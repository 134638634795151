import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import getInitials from 'src/utils/getInitials';

const ClientProfileDetails = (props) => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    image: '',
    name: '',
    email: '',
    phone: '',
    active: '',
    password: null
  });

  const [image, setImage] = useState(null);

  const {
    client,
    saveform,
    updateform,
    loginAsClient,
  } = props;

  useEffect(() => {
    if (client) {
      setValues({
        logo: client.logo,
        name: client.name,
        email: client.email,
        phone: client.phone,
        active: client.active ? 1 : 0,
        password: undefined
      });

      if (client.logo) {
        setImage(client.logo);
      }
    }
  }, [client]);

  const handleChange = (event) => {
    if (event.target.name === 'active') {
      setValues({
        ...values,
        [event.target.name]: event.target.checked ? 1 : 0
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    }
  };

  const handleChangeFile = (event) => {
    setImage(event.target.files[0]);
    setValues({
      ...values,
      logo: URL.createObjectURL(event.target.files[0])
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      if (value !== undefined) {
        formData.append(key, value);
      }
    });

    if (image) {
      formData.append('image', image);
    }

    if (client) {
      await updateform(client.id, formData);
    } else {
      const save = await saveform(formData);
      if (save) {
        navigate('/app/clients', { replace: true });
      }
    }
  };

  const loginAs = async () => {
    const response = await loginAsClient({ client_id: client.id });
    if (response) {
      window.open(`https://www.trocapet.com.br/login-as/${response.token}/${response.user}`, '_blank');
    }
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit}
      {...props}
    >
      <Card>
        <CardHeader
          subheader="Dados do Cliente"
          title="Perfil"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <Avatar
                sx={{ width: 100, height: 100 }}
                lg={{ width: 200, height: 200 }}
                src={values.logo !== '' ? values.logo : `${process.env.REACT_APP_URL}/images/no-image.png`}
              >
                {getInitials(values.name)}
              </Avatar>
              <Typography>Imagem Atual</Typography>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                name="image"
                onChange={handleChangeFile}
                type="file"
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nome"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="E-mail"
                name="email"
                type="email"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChange}
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Celular"
                name="phone"
                onChange={handleChange}
                required
                value={values.phone}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Senha"
                name="password"
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={12}
              xs={12}
            >
              Ativo
              <Switch
                checked={values.active}
                color="success"
                name="active"
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            sx={{ margin: 1 }}
            onClick={loginAs}
          >
            Logar com Usuário
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            sx={{ margin: 1 }}
          >
            Salvar
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ClientProfileDetails.propTypes = {
  documenttypes: PropTypes.object,
  client: PropTypes.object,
  gender: PropTypes.array.isRequired,
  updateform: PropTypes.func.isRequired,
  saveform: PropTypes.func.isRequired,
  loginAsClient: PropTypes.func.isRequired,
};

export default ClientProfileDetails;
