import { Card, Typography } from '@material-ui/core';

const DataNotFoundList = () => (
  <Card style={{
    padding: '10px',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 'bold'
  }}
  >
    <p style={{ padding: '10px' }}>
      <Typography variant="h3" component="h2">
        Dados não encontrados
      </Typography>
    </p>
  </Card>
);

export default DataNotFoundList;
