import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import getInitials from 'src/utils/getInitials';
import CurrencyFormat from 'react-currency-format';

const PlanProfileDetails = (props) => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    image: '',
    name: '',
    description: '',
    value: '',
    type_recurrence: '',
    total_charges: '',
    active: '',
    courtesy: ''
  });

  const [image, setImage] = useState(null);

  const {
    plan,
    saveform,
    updateform,
  } = props;

  useEffect(() => {
    if (plan) {
      setValues({
        image: plan.image,
        name: plan.name,
        description: plan.description,
        value: plan.value,
        type_recurrence: plan.type_recurrence,
        total_charges: plan.total_charges,
        active: plan.active ? 1 : 0,
        courtesy: plan.courtesy ? 1 : 0
      });
    }
  }, [plan]);

  const handleChange = (event) => {
    if (event.target.name === 'active' || event.target.name === 'courtesy') {
      setValues({
        ...values,
        [event.target.name]: event.target.checked ? 1 : 0
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    }
  };

  const handleChangeFile = (event) => {
    setImage(event.target.files[0]);
    setValues({
      ...values,
      image: URL.createObjectURL(event.target.files[0])
    });
  };

  const formatMoney = (value) => {
    let valueUnformat = value.replace('R$', '').replace(',', '').replace('.', '');
    valueUnformat = parseInt(valueUnformat, 10) / 100;
    return valueUnformat;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      if (key === 'value' && value !== '') {
        formData.append(key, formatMoney(value));
      } else if (key !== 'image') {
        formData.append(key, value);
      }
    });

    if (image) {
      formData.append('image', image);
    }

    if (plan) {
      await updateform(plan.id, formData);
    } else {
      const save = await saveform(formData);
      if (save) {
        navigate('/app/plans', { replace: true });
      }
    }
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit}
      {...props}
    >
      <Card>
        <CardHeader
          subheader="Dados da Marca"
          title="Perfil"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <Avatar
                sx={{ width: 100, height: 100 }}
                lg={{ width: 200, height: 200 }}
                src={values.image}
              >
                {getInitials(values.name)}
              </Avatar>
              <Typography>Imagem Atual</Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                name="image"
                onChange={handleChangeFile}
                type="file"
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nome"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={12}
              xs={12}
            >
              <FormControl fullWidth>
                <InputLabel>Tipo de Recorrencia</InputLabel>
                <Select
                  name="type_recurrence"
                  value={values.type_recurrence}
                  onChange={handleChange}
                >
                  <MenuItem value="M">Mensal</MenuItem>
                  <MenuItem value="Y">Anual</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              lg={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Total de Cobranças"
                name="total_charges"
                type="number"
                onChange={handleChange}
                required
                value={values.total_charges}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={12}
              xs={12}
            >
              <CurrencyFormat
                name="value"
                customInput={TextField}
                onChange={handleChange}
                label="Valor"
                fullWidth
                decimalScale={2}
                decimalSeparator=","
                fixedDecimalScale
                placeholder="R$ 0,00"
                prefix="R$ "
                value={values.value}
                thousandSeparator="."
              />
            </Grid>
            <Grid
              item
              md={12}
              lg={12}
              xs={12}
            >
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Descrição"
                name="description"
                onChange={handleChange}
                required
                value={values.description}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={12}
              xs={12}
            >
              Ativo
              <Switch
                checked={values.active}
                name="active"
                color="success"
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={12}
              xs={12}
            >
              Cortesia
              <Switch
                checked={values.courtesy}
                name="courtesy"
                color="success"
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            Salvar
          </Button>
        </Box>
      </Card>
    </form>
  );
};

PlanProfileDetails.propTypes = {
  plan: PropTypes.object,
  updateform: PropTypes.func.isRequired,
  saveform: PropTypes.func.isRequired,
};

export default PlanProfileDetails;
