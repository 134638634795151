import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import ClientListResults from 'src/components/client/ClientListResults';
import ClientListToolbar from 'src/components/client/ClientListToolbar';
import { ClientContext } from 'src/context/ClientContext';
import { useContext, useEffect } from 'react';
import DataNotFoundList from 'src/components/DataNotFoundList';
import LoadingList from 'src/components/LoadingList';

const ClientList = () => {
  const {
    clients,
    loading,
    getClients,
    deleteClient,
    searchClients,
    paginateClient,
  } = useContext(ClientContext);

  useEffect(() => {
    getClients();
  }, []);

  const render = () => {
    if (loading) {
      return (<LoadingList />);
    }

    if (clients && clients.data && clients.data.length > 0) {
      return (
        <ClientListResults clients={clients} exclude={deleteClient} paginate={paginateClient} />
      );
    }
    return (<DataNotFoundList />);
  };

  return (
    <>
      <Helmet>
        <title>
          {`Clientes | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <ClientListToolbar search={searchClients} />
          <Box sx={{ pt: 3 }}>
            {render()}
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default ClientList;
