/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import {
  Box,
  Card,
  CardHeader,
  Divider
} from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { BranchContext } from 'src/context/BranchContext';
import Maps from 'src/components/maps/Maps';

const BranchsMaps = (props) => {
  const { getAllBranchs, branchs } = useContext(BranchContext);
  const [markers, setMarkers] = useState([]);

  useEffect(async () => {
    await getAllBranchs();
  }, []);

  useEffect(() => {
    const makerBranch = [];
    if (branchs.data && branchs.data.length > 0) {
      branchs.data.forEach((branch) => makerBranch.push({
        id: branch.id,
        name: branch.fantasy_name,
        logo: branch.logo !== '' ? branch.logo : `${process.env.REACT_APP_URL}/images/no-image.png`,
        lat: branch.latitude,
        lng: branch.longitude
      }));
    }
    setMarkers(makerBranch);
  }, [branchs]);

  return (
    <Card {...props}>
      <CardHeader title="Localização das Lojas" />
      <Divider />
      <Box sx={{ minWidth: 800 }}>
        <Maps branchs={markers} />
      </Box>
    </Card>
  );
};

export default BranchsMaps;
