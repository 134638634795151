import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import {
  ThumbsUp as ThumbsUpIcon,
  ThumbsDown as ThumbsDownIcon,
  Edit as EditIcon,
  Trash2 as DeleteIcon,
} from 'react-feather';
import { toNumber } from 'lodash';
import DeleteModal from '../modal/DeleteModal';

const PlanBenefitsListResults = ({
  planId,
  planBenefits,
  planBenefitsSettings,
  saveform,
  updateform,
  getForm,
  exclude,
  paginate,
  ...rest
}) => {
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    id: 0,
    name: '',
    description: '',
    active: false
  });
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: null,
    text: 'Tem certeza que deseja excluir este Beneficio ?',
  });

  useEffect(() => {
    if (planBenefitsSettings) {
      if (planBenefitsSettings.current_page === 1) {
        setPage(0);
      } else {
        setPage(toNumber(planBenefitsSettings.current_page) - 1);
      }
    }
  },
  [planBenefitsSettings]);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
    paginate((newPage + 1));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const buttonEdit = async (id) => {
    const benefit = await getForm(id);
    setValues({
      id: toNumber(id),
      name: benefit.name,
      description: benefit.description,
      active: benefit.active ? 1 : 0,
    });
    handleOpen();
  };

  const buttonNew = () => {
    setValues({
      id: toNumber(0),
      name: '',
      description: '',
      active: false
    });
    handleOpen();
  };

  const saveBenefit = async () => {
    values.plan_id = planId;
    if (values.id === 0) {
      await saveform(values);
    } else {
      await updateform(values.id, values);
    }
    handleClose();
  };

  const excludeBilling = async (id) => {
    const confirm = await exclude(id, planId);
    if (confirm) {
      setDeleteModal({
        ...deleteModal,
        open: false
      });
    }
  };

  const deleteBilling = (id) => {
    setDeleteModal({
      ...deleteModal,
      id,
      open: true,
      confirm: excludeBilling,
      cancel: () => { }
    });
  };

  const handleChange = (event) => {
    if (event.target.name === 'active') {
      setValues({
        ...values,
        [event.target.name]: event.target.checked ? 1 : 0
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    }
  };

  return (
    <Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Beneficio da Assinatura
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box sx={{ m: 1 }} />
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                lg={12}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Nome"
                  name="name"
                  onChange={handleChange}
                  required
                  value={values.name}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={12}
                lg={12}
                xs={12}
              >
                <TextField
                  multiline
                  rows={3}
                  fullWidth
                  label="Descrição"
                  name="description"
                  onChange={handleChange}
                  required
                  value={values.description}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                lg={12}
                xs={12}
              >
                Ativo
                <Switch
                  checked={values.active}
                  color="success"
                  name="active"
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
          >
            Fechar
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={saveBenefit}
            autoFocus
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      <Card {...rest}>
        <Box
          sx={{
            display: 'flex',
            marginBottom: '10px',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={buttonNew}
          >
            Novo
          </Button>
        </Box>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Nome
                  </TableCell>
                  <TableCell>
                    Descrição
                  </TableCell>
                  <TableCell>
                    Ativo
                  </TableCell>
                  <TableCell>
                    Editar
                  </TableCell>
                  <TableCell>
                    Excluir
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {planBenefits.data.slice(0, limit).map((benefit) => (
                  <TableRow
                    hover
                    key={benefit.id}
                  >
                    <TableCell>
                      {benefit.name}
                    </TableCell>
                    <TableCell>
                      {benefit.description}
                    </TableCell>
                    <TableCell>
                      {benefit.active ? <ThumbsUpIcon color="#05ffa1" /> : <ThumbsDownIcon color="#e34234" />}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => { buttonEdit(benefit.id); }}
                        variant="contained"
                        color="primary"
                      >
                        <EditIcon />
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => { deleteBilling(benefit.id); }} variant="contained" color="error">
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={planBenefitsSettings.total}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[]}
        />
        <DeleteModal modal={deleteModal} />
      </Card>
    </Grid>
  );
};

PlanBenefitsListResults.propTypes = {
  planBenefits: PropTypes.object.isRequired,
  exclude: PropTypes.func.isRequired,
  paginate: PropTypes.func.isRequired,
  planId: PropTypes.string.isRequired,
  getForm: PropTypes.func.isRequired,
  saveform: PropTypes.func.isRequired,
  updateform: PropTypes.func.isRequired,
  planBenefitsSettings: PropTypes.object.isRequired,
};

export default PlanBenefitsListResults;
