import { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import {
  ThumbsUp as ThumbsUpIcon,
  ThumbsDown as ThumbsDownIcon,
  Edit as EditIcon,
  Trash2 as DeleteIcon,
} from 'react-feather';
import getInitials from 'src/utils/getInitials';
import { useNavigate } from 'react-router';
import DeleteModal from '../modal/DeleteModal';

const BranchListResults = ({
  branchs,
  exclude,
  paginate,
  ...rest
}) => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: null,
    text: 'Tem certeza que deseja excluir este Cliente ?',
  });

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
    paginate((newPage + 1));
  };

  const excludeBranch = async (id) => {
    const confirm = await exclude(id);
    if (confirm) {
      setDeleteModal({
        ...deleteModal,
        open: false
      });
    }
  };

  const deleteBranch = (id) => {
    setDeleteModal({
      ...deleteModal,
      id,
      open: true,
      confirm: excludeBranch,
      cancel: () => { }
    });
  };

  const buttonEdit = (id) => {
    navigate(`/app/branch/${id}`, { replace: true });
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Logo
                </TableCell>
                <TableCell>
                  Nome Fantasia
                </TableCell>
                <TableCell>
                  Nome Social
                </TableCell>
                <TableCell>
                  CEP
                </TableCell>
                <TableCell>
                  Estado
                </TableCell>
                <TableCell>
                  Cidade
                </TableCell>
                <TableCell>
                  Ativo
                </TableCell>
                <TableCell>
                  Editar
                </TableCell>
                <TableCell>
                  Excluir
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {branchs.data.slice(0, limit).map((branch) => (
                <TableRow
                  hover
                  key={branch.id}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Avatar
                        src={branch.logo !== '' ? branch.logo : `${process.env.REACT_APP_URL}/images/no-image.png`}
                        sx={{ mr: 2 }}
                      >
                        {getInitials(branch.name)}
                      </Avatar>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {branch.fantasy_name}
                  </TableCell>
                  <TableCell>
                    {branch.social_name}
                  </TableCell>
                  <TableCell>
                    {branch.zip_code}
                  </TableCell>
                  <TableCell>
                    {branch.state}
                  </TableCell>
                  <TableCell>
                    {branch.city}
                  </TableCell>
                  <TableCell>
                    {branch.active ? <ThumbsUpIcon color="#05ffa1" /> : <ThumbsDownIcon color="#e34234" />}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => { buttonEdit(branch.id); }} variant="contained" color="primary">
                      <EditIcon />
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => { deleteBranch(branch.id); }} variant="contained" color="error">
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={branchs.meta.total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[]}
      />
      <DeleteModal modal={deleteModal} />
    </Card>
  );
};

BranchListResults.propTypes = {
  branchs: PropTypes.array.isRequired,
  exclude: PropTypes.array.isRequired,
  paginate: PropTypes.array.isRequired
};

export default BranchListResults;
