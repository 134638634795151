import { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import {
  Edit as EditIcon
} from 'react-feather';
import getInitials from 'src/utils/getInitials';
import { useNavigate } from 'react-router';

const OfferListResults = ({
  type,
  offers,
  exclude,
  paginate,
  ...rest
}) => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
    paginate((newPage + 1));
  };

  const buttonEdit = (client, id) => {
    navigate(`/app/myOffer/${client}/${id}`, { replace: true });
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Imagem Produto
                </TableCell>
                <TableCell>
                  Produto
                </TableCell>
                <TableCell>
                  Valor Oferta
                </TableCell>
                <TableCell>
                  Ofertante
                </TableCell>
                <TableCell>
                  Valor de Produtos Oferecidos
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
                <TableCell>
                  Editar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {offers.data.slice(0, limit).map((offer) => (
                <TableRow
                  hover
                  key={offer.id}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Avatar
                        src={offer.product.images && offer.product.images.image !== '' ? offer.product.images.image : `${process.env.REACT_APP_URL}/images/no-image.png`}
                        sx={{ mr: 2 }}
                      >
                        {getInitials(offer.name)}
                      </Avatar>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {offer.product.name}
                  </TableCell>
                  <TableCell>
                    {`R$ ${offer.amount_product_offer}`}
                  </TableCell>
                  <TableCell>
                    {offer.client.name}
                  </TableCell>
                  <TableCell>
                    {`R$ ${offer.amount_products}`}
                  </TableCell>
                  <TableCell>
                    {offer.status}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => { buttonEdit(offer.client_id, offer.id); }} variant="contained" color="primary">
                      <EditIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={offers.meta.total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[]}
      />
    </Card>
  );
};

OfferListResults.propTypes = {
  offers: PropTypes.array.isRequired,
  exclude: PropTypes.array.isRequired,
  paginate: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};

export default OfferListResults;
