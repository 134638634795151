import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import getInitials from 'src/utils/getInitials';

const CategoryProfileDetails = (props) => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    image: '',
    name: '',
    active: ''
  });

  const [image, setImage] = useState(null);

  const {
    category,
    saveform,
    updateform,
  } = props;

  useEffect(() => {
    if (category) {
      setValues({
        image: category.logo,
        name: category.name,
        active: category.active
      });
    }
  }, [category]);

  const handleChange = (event) => {
    if (event.target.name === 'active') {
      setValues({
        ...values,
        [event.target.name]: event.target.checked ? 1 : 0
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    }
  };

  const handleChangeFile = (event) => {
    setImage(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    if (image) {
      formData.append('logo', image);
    }

    if (category) {
      await updateform(category.id, formData);
    } else {
      const save = await saveform(formData);
      if (save) {
        navigate('/app/categories', { replace: true });
      }
    }
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit}
      {...props}
    >
      <Card>
        <CardHeader
          subheader="Dados da Marca"
          title="Perfil"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <Avatar
                sx={{ width: 100, height: 100 }}
                lg={{ width: 200, height: 200 }}
                src={values.image}
              >
                {getInitials(values.name)}
              </Avatar>
              <Typography>Imagem Atual</Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                name="image"
                onChange={handleChangeFile}
                type="file"
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nome"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={12}
              xs={12}
            >
              Ativo
              <Switch
                checked={values.active}
                name="active"
                color="success"
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            Salvar
          </Button>
        </Box>
      </Card>
    </form>
  );
};

CategoryProfileDetails.propTypes = {
  category: PropTypes.object,
  updateform: PropTypes.func.isRequired,
  saveform: PropTypes.func.isRequired,
};

export default CategoryProfileDetails;
