import {
  React,
  useState,
  createContext,
  useContext
} from 'react';
import api from 'src/services/api';
import { SiteContext } from './SiteContext';

export const PlanContext = createContext();

const PlanProvider = (prop) => {
  const baseUrl = 'admin/plan';
  const { children } = prop;
  const [plan, setPlan] = useState(null);
  const [plans, setPlans] = useState([]);
  const [urlPaginate, setUrlPaginate] = useState();
  const {
    setNotifyMessage,
    notifyMessageSave,
    closeNotifyMessage,
    notifyMessageError,
    loading,
    setLoading
  } = useContext(SiteContext);
  const [search, setSearch] = useState();

  const getPlans = async () => {
    setLoading(true);
    await api.get(`${baseUrl}/all`)
      .then((response) => {
        setPlans(response.data);
        setUrlPaginate(response.data.meta.path);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getPlan = async (id) => {
    setLoading(true);
    await api.get(`${baseUrl}/get/${id}`)
      .then((response) => {
        setPlan(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const searchPlans = async (param) => {
    setLoading(true);
    if (!param) {
      await getPlans();
    } else {
      await api.post(`${baseUrl}/search`, { param })
        .then((response) => {
          setPlans(response.data);
          setUrlPaginate(response.data.meta.path);
          setSearch(param);
          setLoading(false);
        })
        .catch((err) => {
          setPlans([]);
          setLoading(false);
          console.log(err);
        });
    }
  };

  const savePlan = async (data) => {
    setLoading(true);
    const serverResponse = await api.post(`${baseUrl}/create`, data)
      .then((response) => {
        setPlan(response.data.data);
        setLoading(false);
        notifyMessageSave('Salvo');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
    return serverResponse;
  };

  const updatePlan = async (id, data) => {
    setLoading(true);
    await api.post(`${baseUrl}/update/${id}`, data)
      .then((response) => {
        setPlan(response.data.data);
        notifyMessageSave('Atualizado');
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const paginatePlan = async (number) => {
    setLoading(true);
    if (search) {
      await api.post(`${urlPaginate}?page=${number}`, { search })
        .then((response) => {
          setPlans(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setPlans([]);
          setLoading(false);
          console.log(err);
        });
    } else {
      await api.get(`${urlPaginate}?page=${number}`)
        .then((response) => {
          setPlans(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setPlans([]);
          setLoading(false);
          console.log(err);
        });
    }
  };

  const deletePlan = async (id) => {
    setLoading(true);
    const response = await api.delete(`${baseUrl}/delete/${id}`)
      .then(() => {
        setLoading(false);
        notifyMessageSave('excluido');
        getPlans();
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
    return response;
  };

  return (
    <PlanContext.Provider
      value={{
        plan,
        plans,
        loading,
        urlPaginate,
        getPlans,
        savePlan,
        updatePlan,
        setPlan,
        deletePlan,
        paginatePlan,
        getPlan,
        setNotifyMessage,
        searchPlans,
        closeNotifyMessage,
        notifyMessageError,
        notifyMessageSave
      }}
    >
      { children }
    </PlanContext.Provider>

  );
};

export default PlanProvider;
