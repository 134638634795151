import {
  React,
  useState,
  createContext,
  useContext
} from 'react';
import api from 'src/services/api';
import { SiteContext } from './SiteContext';

export const DashboardContext = createContext();

const DashboardProvider = (prop) => {
  const baseUrl = 'admin';
  const { children } = prop;
  const [clientAmount, setClientAmount] = useState(null);
  const [offerProgressAmount, setOfferProgressAmount] = useState(null);
  const [offerEndedAmount, setOfferEndedAmount] = useState(null);
  const [productsLattes, setProductsLattes] = useState([]);
  const [offersLattes, setOffersLattes] = useState([]);
  const [offersLastSevenDays, setOffersLastSevenDays] = useState([]);
  const [billingLate, setBillingLate] = useState([]);
  const [amountProspected, setAmountProspected] = useState(0);
  const [amountReceived, setAmountReceived] = useState(0);
  const [amountWaiting, setAmountWaiting] = useState(0);
  const [amountLate, setAmountLate] = useState(0);
  const [amountLower, setAmountLower] = useState(0);

  const {
    setNotifyMessage,
    notifyMessageSave,
    closeNotifyMessage,
    notifyMessageError,
    loading,
    setLoading
  } = useContext(SiteContext);

  const getClientAmount = async () => {
    setLoading(true);
    await api.get(`${baseUrl}/client/dashboard/amount`)
      .then((response) => {
        setClientAmount(response.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getOfferProgress = async () => {
    setLoading(true);
    await api.get(`${baseUrl}/offer/dashboard/amount/P`)
      .then((response) => {
        setOfferProgressAmount(response.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getOfferEnded = async () => {
    setLoading(true);
    await api.get(`${baseUrl}/offer/dashboard/amount/E`)
      .then((response) => {
        setOfferEndedAmount(response.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getLastProducts = async () => {
    setLoading(true);
    await api.get(`${baseUrl}/product/dashboard/latter`)
      .then((response) => {
        setProductsLattes(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getLastOffer = async () => {
    await api.get(`${baseUrl}/offer/dashboard/latter`)
      .then((response) => {
        setOffersLattes(response.data.data);
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  const getLastOfferSevenDays = async () => {
    setLoading(true);
    await api.get(`${baseUrl}/offer/dashboard/lastSevenDay`)
      .then((response) => {
        setOffersLastSevenDays(response.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getBillingLate = async () => {
    setLoading(true);
    try {
      const response = await api.get(`${baseUrl}/subscriptionBilling/dashboard/late`);
      console.log('API response:', response.data); // Adicionando console.log para depuração
      setBillingLate(response.data.data);
    } catch (err) {
      console.error('Merda API request error:', err);
    } finally {
      setLoading(false);
    }
  };

  const getAmountProspected = async () => {
    setLoading(true);
    try {
      const response = await api.get(`${baseUrl}/subscriptionBilling/dashboard/amountByStatus/0`);
      setAmountProspected(response.data);
    } catch (err) {
      console.error('Merda API request error:', err);
    } finally {
      setLoading(false);
    }
  };

  const getAmountWaiting = async () => {
    setLoading(true);
    try {
      const response = await api.get(`${baseUrl}/subscriptionBilling/dashboard/amountByStatus/W`);
      setAmountWaiting(response.data);
    } catch (err) {
      console.error('Merda API request error:', err);
    } finally {
      setLoading(false);
    }
  };

  const getAmountLower = async () => {
    setLoading(true);
    try {
      const response = await api.get(`${baseUrl}/subscriptionBilling/dashboard/amountByStatus/D`);
      setAmountLower(response.data);
    } catch (err) {
      console.error('Merda API request error:', err);
    } finally {
      setLoading(false);
    }
  };

  const getAmountReceived = async () => {
    setLoading(true);
    try {
      const response = await api.get(`${baseUrl}/subscriptionBilling/dashboard/amountByStatus/P`);
      setAmountReceived(response.data);
    } catch (err) {
      console.error('Merda API request error:', err);
    } finally {
      setLoading(false);
    }
  };

  const getAmountLate = async () => {
    setLoading(true);
    try {
      const response = await api.get(`${baseUrl}/subscriptionBilling/dashboard/amountByStatus/L`);
      setAmountLate(response.data);
    } catch (err) {
      console.error('Merda API request error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardContext.Provider
      value={{
        clientAmount,
        billingLate,
        amountProspected,
        amountReceived,
        amountLate,
        getClientAmount,
        getBillingLate,
        getAmountLower,
        amountLower,
        getAmountWaiting,
        amountWaiting,
        getAmountProspected,
        getAmountReceived,
        getAmountLate,
        offerProgressAmount,
        getOfferProgress,
        getOfferEnded,
        getLastProducts,
        getLastOffer,
        getLastOfferSevenDays,
        offersLastSevenDays,
        productsLattes,
        offersLattes,
        offerEndedAmount,
        loading,
        setNotifyMessage,
        closeNotifyMessage,
        notifyMessageError,
        notifyMessageSave
      }}
    >
      { children }
    </DashboardContext.Provider>

  );
};

export default DashboardProvider;
