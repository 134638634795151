import { Helmet } from 'react-helmet';
import {
  AppBar,
  Box,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import CategoryProfileDetails from 'src/components/category/CategoryProfileDetails';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { CategoryContext } from 'src/context/CategoryContext';

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const tab = {
  color: '#FFF'
};

const tabSelected = {
  color: '#FFF',
  borderBottom: '5px solid red'
};

const CategoryForm = () => {
  const { id } = useParams();
  const {
    category,
    loading,
    getCategory,
    saveCategory,
    updateCategory,
    setCategory,
  } = useContext(CategoryContext);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (id) {
      getCategory(id);
    }
  }, []);

  useEffect(() => {
    if (!id) {
      setCategory(null);
    }
  }, [category]);

  return (
    <>
      <Helmet>
        <title>
          {`Marca | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          {loading === false
            ? (
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                      <Tab style={value === 0 ? tabSelected : tab} label="Dados" {...a11yProps(0)} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={value} index={0} style={{ backgroundColor: '#fff' }}>
                    <CategoryProfileDetails
                      category={category}
                      saveform={saveCategory}
                      updateform={updateCategory}
                    />
                  </TabPanel>
                </Grid>
              </Grid>
            )
            : <>Carregando</> }
        </Container>
      </Box>
    </>
  );
};
export default CategoryForm;
