import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

const LatestProducts = (props) => {
  const navigate = useNavigate();
  const { lastProducts } = props;
  const products = lastProducts;

  const buttonEdit = (id) => {
    navigate(`/app/product/${id}`, { replace: true });
  };

  return (
    <Card {...props}>
      <CardHeader
        subtitle={`${products.length} in total`}
        title="Ultimos produtos"
      />
      <Divider />
      <List>
        {products.map((product, i) => (
          <ListItem
            divider={i < products.length - 1}
            key={product.id}
          >
            <ListItemAvatar>
              <img
                alt={product.name}
                src={product.images && product.images.image !== '' ? product.images.image : `${process.env.REACT_APP_URL}/images/no-image.png`}
                style={{
                  height: 48,
                  width: 48
                }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={product.name}
              secondary={`Atualizado ${product.updated_at}`}
            />
            <IconButton
              edge="end"
              size="small"
            >
              <VisibilityIcon onClick={() => { buttonEdit(product.id); }} />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button
          sx={{ display: 'none' }}
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          View all
        </Button>
      </Box>
    </Card>
  );
};

LatestProducts.propTypes = {
  lastProducts: PropTypes.array,
};

export default LatestProducts;
