import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
} from '@material-ui/core';
import UserListResults from 'src/components/user/UserListResults';
import UserListToolbar from 'src/components/user/UserListToolbar';
import { UserContext } from 'src/context/UserContext';
import { useContext, useEffect } from 'react';
import LoadingList from 'src/components/LoadingList';
import DataNotFoundList from 'src/components/DataNotFoundList';

const UserList = () => {
  const {
    users,
    loading,
    getUsers,
    deleteUser,
    searchUsers,
    paginateUser,
  } = useContext(UserContext);
  useEffect(async () => {
    await getUsers();
  }, []);

  useEffect(() => {
  }, [users]);

  const render = () => {
    if (loading) {
      return (<LoadingList />);
    }

    if (users && users.data && users.data.length > 0) {
      return (
        <UserListResults users={users} exclude={deleteUser} paginate={paginateUser} />
      );
    }
    return (<DataNotFoundList />);
  };

  return (
    <>
      <Helmet>
        <title>
          {`Marcas | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <UserListToolbar search={searchUsers} />
          <Box sx={{ pt: 3 }}>
            {render()}
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default UserList;
