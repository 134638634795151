import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Alert,
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import { useContext, useState } from 'react';
import { UserContext } from 'src/context/UserContext';
import { SiteContext } from 'src/context/SiteContext';

const Login = () => {
  const navigate = useNavigate();
  const { expiration } = useParams();
  const { loginUser } = useContext(UserContext);
  const { setNotifyMessage } = useContext(SiteContext);
  const [values, setValues] = useState({
    email: '',
    password: '',
    isSubmitting: false,
  });

  const login = async (data) => {
    const loginData = await loginUser(data);
    if (loginData) {
      setNotifyMessage({
        text: 'Login realizado com sucesso, aguarde ...',
        type: 'success',
        open: true,
      });
      setTimeout(() => {
        navigate('/app/dashboard', { replace: true });
      }, 2000);
    } else {
      setNotifyMessage({
        text: 'Erro ao realizar o login, usuário ou senha errado',
        type: 'error',
        open: true,
      });
      setValues({
        ...values,
        isSubmitting: false
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      isSubmitting: true
    });
    const data = {
      email: values.email,
      password: values.password
    };
    login(data);
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <>
      <Helmet>
        <title>
          {`Login | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 3 }}>
              <Typography
                color="textPrimary"
                variant="h2"
              >
                Entrar
              </Typography>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                Entre com seu e-mail e senha
              </Typography>
            </Box>
            <TextField
              fullWidth
              label="Email"
              margin="normal"
              name="email"
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Senha"
              margin="normal"
              name="password"
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            {expiration
              ? (
                <Alert severity="warning">Sua sessão expirou, faça login novamente</Alert>
              )
              : <></>}
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={values.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Entrar
              </Button>
            </Box>
            <Typography
              color="textSecondary"
              variant="body1"
            >
              Esqueceu a senha ?
              {' '}
              <Link
                component={RouterLink}
                to="/register"
                variant="h6"
              >
                recuperar
              </Link>
            </Typography>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default Login;
