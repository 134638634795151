/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import PropTypes from 'prop-types';
import Maps from './Maps';

export default function LoadMaps({ markers, draggable, returnCoord }) {
  return <Maps branchs={markers} draggable={draggable} returnCoord={returnCoord} />;
}

LoadMaps.propTypes = {
  markers: PropTypes.array,
  draggable: PropTypes.bool,
  returnCoord: PropTypes.func,
};
