import { Helmet } from 'react-helmet';
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import PropTypes from 'prop-types';
import { SubscriptionContext } from 'src/context/SubscriptionContext';
import SubscriptionProfileDetails from 'src/components/subscription/SubscriptionProfileDetails';
import SubscriptionBillingListResults from 'src/components/subscriptionBilling/SubscriptionBillingListResults';
import { SubscriptionBillingContext } from 'src/context/SubscriptionBillingContext';

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const tab = {
  color: '#FFF'
};

const tabSelected = {
  color: '#FFF',
  borderBottom: '5px solid red'
};

const SubscriptionForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    loading,
    subscription,
    setSubscription,
    getSubscription,
    saveSubscription,
    updateSubscription,
  } = useContext(SubscriptionContext);

  const {
    subscriptionBillings,
    subscriptionBillingsSettings,
    updateSubscriptionBilling,
    saveSubscriptionBilling,
    getSubscriptionBilling,
    getSubscriptionBillingsBySubscription,
    paginateSubscriptionBilling,
    deleteSubscriptionBilling,
  } = useContext(SubscriptionBillingContext);

  const [value, setValue] = useState(0);

  const saveUpdateList = async (data) => {
    await saveSubscriptionBilling(data);
    await getSubscriptionBillingsBySubscription(id);
  };

  const updateUpdateList = async (data, billingId) => {
    await updateSubscriptionBilling(billingId, data);
    await getSubscriptionBillingsBySubscription(id);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(async () => {
    if (id) {
      await getSubscription(id);
      await getSubscriptionBillingsBySubscription(id);
    } else {
      setSubscription(null);
    }
  }, []);

  const backClient = () => {
    navigate(`/app/client/${subscription.client_id}`, { replace: true });
  };

  return (
    <>
      <Helmet>
        <title>
          {`Client | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          {loading === false
            ? (
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      marginBottom: '10px',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={backClient}
                    >
                      Voltar
                    </Button>
                  </Box>
                  <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                      <Tab style={value === 0 ? tabSelected : tab} label="Assinatura" {...a11yProps(0)} />
                      <Tab style={value === 1 ? tabSelected : tab} label="Pagamentos" {...a11yProps(1)} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={value} index={0} style={{ backgroundColor: '#fff' }}>
                    <SubscriptionProfileDetails
                      subscription={subscription}
                      saveform={saveSubscription}
                      updateform={updateSubscription}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1} style={{ backgroundColor: '#fff' }}>
                    <SubscriptionBillingListResults
                      subscriptionId={id}
                      exclude={(billingId, subscriptionId) => { deleteSubscriptionBilling(billingId, subscriptionId); }}
                      subscriptionBillingsSettings={subscriptionBillingsSettings}
                      paginate={paginateSubscriptionBilling}
                      getForm={getSubscriptionBilling}
                      subscriptionBillings={subscriptionBillings}
                      saveform={(data) => { saveUpdateList(data); }}
                      updateform={(data, billingId) => { updateUpdateList(data, billingId); }}
                    />
                  </TabPanel>
                </Grid>
              </Grid>
            )
            : <></> }
        </Container>
      </Box>
    </>
  );
};
export default SubscriptionForm;
