import { Helmet } from 'react-helmet';
import {
  AppBar,
  Box,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import PlanProfileDetails from 'src/components/plan/PlanProfileDetails';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { PlanContext } from 'src/context/PlanContext';
import { PlanBenefitsContext } from 'src/context/PlanBenefitsContext';
import PlanBenefitsListResults from 'src/components/planBenefits/PlanBenefitsListResults';

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const tab = {
  color: '#FFF'
};

const tabSelected = {
  color: '#FFF',
  borderBottom: '5px solid red'
};

const PlanForm = () => {
  const { id } = useParams();
  const {
    plan,
    loading,
    getPlan,
    savePlan,
    updatePlan,
    setPlan,
  } = useContext(PlanContext);

  const {
    planBenefits,
    getPlanBenefit,
    savePlanBenefit,
    updatePlanBenefit,
    planBenefitsSettings,
    getPlanBenefitsByPlan,
    deletePlanBenefit,
  } = useContext(PlanBenefitsContext);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(async () => {
    if (id) {
      await getPlanBenefitsByPlan(id);
      await getPlan(id);
    }
  }, []);

  useEffect(() => {
    if (!id) {
      setPlan(null);
    }
  }, [plan]);

  useEffect(() => {
    if (planBenefitsSettings) {
      console.log(planBenefitsSettings);
    }
  }, [planBenefitsSettings]);

  return (
    <>
      <Helmet>
        <title>
          {`Marca | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          {loading === false
            ? (
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                      <Tab style={value === 0 ? tabSelected : tab} label="Dados" {...a11yProps(0)} />
                      <Tab style={value === 1 ? tabSelected : tab} label="Beneficios" {...a11yProps(1)} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={value} index={0} style={{ backgroundColor: '#fff' }}>
                    <PlanProfileDetails
                      plan={plan}
                      saveform={savePlan}
                      updateform={updatePlan}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1} style={{ backgroundColor: '#fff' }}>
                    <PlanBenefitsListResults
                      planId={id}
                      planBenefitsSettings={planBenefitsSettings}
                      planBenefits={planBenefits}
                      saveform={savePlanBenefit}
                      updateform={(data, benefitId) => { updatePlanBenefit(data, benefitId); }}
                      getForm={getPlanBenefit}
                      exclude={(benefitId, planId) => { deletePlanBenefit(benefitId, planId); }}
                    />
                  </TabPanel>
                </Grid>
              </Grid>
            )
            : <>Carregando</> }
        </Container>
      </Box>
    </>
  );
};
export default PlanForm;
