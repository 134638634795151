import {
  React,
  useState,
  createContext,
  useContext
} from 'react';
import api from 'src/services/api';
import { SiteContext } from './SiteContext';

export const SubscriptionContext = createContext();

const SubscriptionProvider = (prop) => {
  const baseUrl = 'admin/subscription';
  const { children } = prop;
  const [subscription, setSubscription] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionsSettings, setSubscriptionSettings] = useState([

  ]);
  const [urlPaginate, setUrlPaginate] = useState();
  const {
    setNotifyMessage,
    notifyMessageSave,
    closeNotifyMessage,
    notifyMessageError,
    loading,
    setLoading
  } = useContext(SiteContext);

  const getAllSubscriptions = async () => {
    setLoading(true);
    await api.get('admin/subscription/all')
      .then((response) => {
        setSubscriptions(response.data);
        setSubscriptionSettings(response.data.meta);
        setUrlPaginate(response.data.meta.path);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getSubscriptionsByClient = async (client) => {
    setLoading(true);
    await api.get(`${baseUrl}/all/byClient/${client}`)
      .then((response) => {
        setSubscriptions(response.data);
        setSubscriptionSettings(response.data.meta);
        setUrlPaginate(response.data.meta.path);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getSubscription = async (id) => {
    setLoading(true);
    await api.get(`${baseUrl}/get/${id}`)
      .then((response) => {
        setSubscription(response.data.data);
        setLoading(false);
        return response.data.data;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  return (
    <SubscriptionContext.Provider
      value={{
        subscription,
        subscriptions,
        loading,
        urlPaginate,
        subscriptionsSettings,
        getAllSubscriptions,
        getSubscriptionsByClient,
        setSubscription,
        getSubscription,
        setNotifyMessage,
        closeNotifyMessage,
        notifyMessageError,
        notifyMessageSave
      }}
    >
      { children }
    </SubscriptionContext.Provider>

  );
};

export default SubscriptionProvider;
