import {
  React,
  useState,
  createContext,
  useContext
} from 'react';
import api from 'src/services/api';
import { SiteContext } from './SiteContext';

export const SubscriptionBillingContext = createContext();

const SubscriptionBillingProvider = (prop) => {
  const baseUrl = 'admin/subscriptionBilling';
  const { children } = prop;
  const [subscriptionBilling, setSubscriptionBilling] = useState(null);
  const [subscriptionBillings, setSubscriptionBillings] = useState([]);
  const [subscriptionBillingsSettings, setSubscriptionBillingSettings] = useState([

  ]);
  const [urlPaginate, setUrlPaginate] = useState();
  const {
    setNotifyMessage,
    notifyMessageSave,
    closeNotifyMessage,
    notifyMessageError,
    loading,
    setLoading
  } = useContext(SiteContext);

  const getAllSubscriptionBillings = async () => {
    setLoading(true);
    await api.get('admin/subscription/all')
      .then((response) => {
        setSubscriptionBillings(response.data);
        setSubscriptionBillingSettings(response.data.meta);
        setUrlPaginate(response.data.meta.path);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getSubscriptionBillingsBySubscription = async (subscription) => {
    setLoading(true);
    await api.get(`${baseUrl}/all/bySubscription/${subscription}`)
      .then((response) => {
        setSubscriptionBillings(response.data);
        setSubscriptionBillingSettings(response.data.meta);
        setUrlPaginate(response.data.meta.path);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getSubscriptionBilling = async (id) => {
    try {
      const response = await api.get(`${baseUrl}/get/${id}`);
      setSubscriptionBilling(response.data.data);
      return response.data.data;
    } catch (err) {
      console.error(err);
      return null; // Retorne null ou false para indicar falha
    }
  };

  const saveSubscriptionBilling = async (data) => {
    setLoading(true);
    const serverResponse = await api.post(`${baseUrl}/create`, data)
      .then((response) => {
        setSubscriptionBilling(response.data.data);
        setLoading(false);
        notifyMessageSave('Salvo');
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
    return serverResponse;
  };

  const updateSubscriptionBilling = async (id, data) => {
    setLoading(true);
    await api.post(`${baseUrl}/update/${id}`, data)
      .then((response) => {
        setSubscriptionBilling(response.data.data);
        notifyMessageSave('Atualizado');
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const paginateSubscriptionBilling = async (number) => {
    setLoading(true);
    await api.get(`${urlPaginate}?page=${number}`)
      .then((response) => {
        setSubscriptionBillings(response.data);
        setSubscriptionBillingSettings(response.data.meta);
        setLoading(false);
      })
      .catch((err) => {
        setSubscriptionBillings([]);
        setLoading(false);
        console.log(err);
      });
  };

  const deleteSubscriptionBilling = async (id, subscriptionId) => {
    setLoading(true);
    const response = await api.delete(`${baseUrl}/delete/${id}`)
      .then(() => {
        setLoading(false);
        notifyMessageSave('excluido');
        getSubscriptionBillingsBySubscription(subscriptionId);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
    return response;
  };

  return (
    <SubscriptionBillingContext.Provider
      value={{
        subscriptionBilling,
        subscriptionBillings,
        deleteSubscriptionBilling,
        saveSubscriptionBilling,
        updateSubscriptionBilling,
        paginateSubscriptionBilling,
        loading,
        urlPaginate,
        subscriptionBillingsSettings,
        getAllSubscriptionBillings,
        getSubscriptionBillingsBySubscription,
        setSubscriptionBilling,
        getSubscriptionBilling,
        setNotifyMessage,
        closeNotifyMessage,
        notifyMessageError,
        notifyMessageSave
      }}
    >
      { children }
    </SubscriptionBillingContext.Provider>

  );
};

export default SubscriptionBillingProvider;
