import { Helmet } from 'react-helmet';
import {
  Box,
  colors,
  Container,
  Grid
} from '@material-ui/core';
import Budget from 'src/components/dashboard/Budget';
import LatestOrders from 'src/components/dashboard//LatestOrders';
import LatestProducts from 'src/components/dashboard//LatestProducts';
import Sales from 'src/components/dashboard//Sales';
import TasksProgress from 'src/components/dashboard//TasksProgress';
import TotalCustomers from 'src/components/dashboard//TotalCustomers';
import TotalProfit from 'src/components/dashboard//TotalProfit';
import TrafficByDevice from 'src/components/dashboard//TrafficByDevice';
import { useContext, useEffect } from 'react';
import { DashboardContext } from 'src/context/DashboardContext';
import BranchsMaps from 'src/components/dashboard/BranchsMaps';
import SubscriptionBillingLate from 'src/components/dashboard/SubscriptionBillingLate';

const Dashboard = () => {
  const {
    getClientAmount,
    getOfferProgress,
    getOfferEnded,
    getLastProducts,
    getLastOffer,
    getLastOfferSevenDays,
    clientAmount,
    offerProgressAmount,
    offerEndedAmount,
    productsLattes,
    offersLattes,
    offersLastSevenDays,
    getBillingLate,
    billingLate,
    getAmountProspected,
    amountProspected,
    getAmountReceived,
    amountReceived,
    getAmountLate,
    amountLate,
    getAmountWaiting,
    amountWaiting,
    getAmountLower,
    amountLower,
  } = useContext(DashboardContext);

  useEffect(() => {
    getClientAmount();
    getOfferProgress();
    getOfferEnded();
    getLastProducts();
    getLastOffer();
    getLastOfferSevenDays();
    getBillingLate();
    getAmountProspected();
    getAmountReceived();
    getAmountLate();
    getAmountWaiting();
    getAmountLower();
  }, []);

  return (
    <>
      <Helmet>
        <title>Dashboard | Material Kit</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <Budget amount={amountProspected} title="Projeção Mês" color={colors.blue[600]} lower={amountLower} />
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <Budget amount={amountWaiting} title="A receber no Mês" color={colors.grey[600]} />
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <Budget amount={amountReceived} title="Recebido no Mês" color={colors.green[600]} />
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <Budget amount={amountLate} title="Atrazados no Mês" />
            </Grid>
            <Grid
              item
              lg={4}
              sm={6}
              xl={4}
              xs={12}
            >
              <TotalCustomers amount={clientAmount} />
            </Grid>
            <Grid
              item
              lg={4}
              sm={6}
              xl={4}
              xs={12}
            >
              <TasksProgress offers={offerProgressAmount} />
            </Grid>
            <Grid
              item
              lg={4}
              sm={6}
              xl={4}
              xs={12}
            >
              <TotalProfit offers={offerEndedAmount} sx={{ height: '100%' }} />
            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xl={12}
              xs={12}
            >
              <SubscriptionBillingLate billings={billingLate} />
            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xl={12}
              xs={12}
            >
              <BranchsMaps />
            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
            >
              <Sales offers={offersLastSevenDays} />
            </Grid>
            <Grid
              item
              lg={4}
              md={6}
              xl={3}
              xs={12}
              sx={{ display: 'none' }}
            >
              <TrafficByDevice sx={{ height: '100%' }} />
            </Grid>
            <Grid
              item
              lg={4}
              md={6}
              xl={3}
              xs={12}
            >
              <LatestProducts lastProducts={productsLattes} sx={{ height: '100%' }} />
            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xl={12}
              xs={12}
            >
              <LatestOrders offers={offersLattes} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
