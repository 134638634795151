import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import OfferListResults from 'src/components/offer/OfferListResults';
import OfferListToolbar from 'src/components/offer/OfferListToolbar';
import { useContext } from 'react';
import LoadingList from 'src/components/LoadingList';
import DataNotFoundList from 'src/components/DataNotFoundList';
import { OfferContext } from 'src/context/OfferContext';

const OfferList = ({ offers, type }) => {
  const {
    loading,
    deleteOffer,
    searchOffers,
    paginateOffer,
  } = useContext(OfferContext);

  const render = () => {
    if (loading) {
      return (<LoadingList />);
    }

    if (offers && offers.data && offers.data.length > 0) {
      return (
        <OfferListResults type={type} offers={offers} exclude={deleteOffer} paginate={paginateOffer} />
      );
    }
    return (<DataNotFoundList />);
  };

  return (
    <>
      <OfferListToolbar search={searchOffers} />
      <Box sx={{ pt: 3 }}>
        {render()}
      </Box>
    </>
  );
};

OfferList.propTypes = {
  offers: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};

export default OfferList;
