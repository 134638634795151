import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import BranchListResults from 'src/components/branch/BranchListResults';
import BranchListToolbar from 'src/components/branch/BranchListToolbar';
import { BranchContext } from 'src/context/BranchContext';
import { useContext } from 'react';
import LoadingList from 'src/components/LoadingList';
import DataNotFoundList from 'src/components/DataNotFoundList';

const BranchList = ({ branchs }) => {
  const {
    loading,
    deleteBranch,
    searchBranchs,
    paginateBranch,
  } = useContext(BranchContext);

  const render = () => {
    if (loading) {
      return (<LoadingList />);
    }

    if (branchs && branchs.data && branchs.data.length > 0) {
      return (
        <BranchListResults branchs={branchs} exclude={deleteBranch} paginate={paginateBranch} />
      );
    }
    return (<DataNotFoundList />);
  };

  return (
    <>
      <BranchListToolbar search={searchBranchs} />
      <Box sx={{ pt: 3 }}>
        {render()}
      </Box>
    </>
  );
};

BranchList.propTypes = {
  branchs: PropTypes.array.isRequired,
};

export default BranchList;
