import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography
} from '@material-ui/core';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import PropTypes from 'prop-types';
import { OfferContext } from 'src/context/OfferContext';
import OfferProfileDetails from 'src/components/offer/OfferProfileDetails';
import DataNotFoundList from 'src/components/DataNotFoundList';
import LoadingList from 'src/components/LoadingList';

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const MyOfferForm = () => {
  const { client, id } = useParams();
  const navigate = useNavigate();
  const {
    offer,
    loading,
    setOffer,
    getOffer,
  } = useContext(OfferContext);

  useEffect(async () => {
    if (id) {
      await getOffer(client, id);
    } else {
      setOffer(null);
    }
  }, []);

  const backClient = () => {
    navigate(`/app/client/${offer.client_id}`, { replace: true });
  };

  const render = () => {
    if (loading) {
      return (<LoadingList />);
    }

    if (offer) {
      return (
        <OfferProfileDetails
          offer={offer}
        />
      );
    }
    return (<DataNotFoundList />);
  };

  return (
    <>
      <Helmet>
        <title>
          {`Client | ${process.env.REACT_APP_NAME}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          {loading === false
            ? (
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      marginBottom: '10px',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={backClient}
                    >
                      Voltar
                    </Button>
                  </Box>
                  {render()}
                </Grid>
              </Grid>
            )
            : <></> }
        </Container>
      </Box>
    </>
  );
};
export default MyOfferForm;
