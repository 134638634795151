import {
  React,
  useState,
  createContext
} from 'react';
import api from 'src/services/api';

export const SiteContext = createContext();

const SiteProvider = (prop) => {
  const { children } = prop;
  const [loading, setLoading] = useState(false);
  const [notifyMessage, setNotifyMessage] = useState({
    text: null,
    type: 'success',
    open: false
  });

  const closeNotifyMessage = () => {
    setNotifyMessage({
      ...notifyMessage,
      open: false
    });
  };

  const notifyMessageSave = (action) => {
    setNotifyMessage({
      text: `Registro ${action} com Sucesso !`,
      type: 'success',
      open: true
    });
  };

  const notifyMessageError = (action, err) => {
    setNotifyMessage({
      text: `Erro ao ${action} =( ${err}`,
      type: 'error',
      open: true
    });
  };

  const getAddressByZipCode = async (zipCode) => (
    api.get(`https://viacep.com.br/ws/${zipCode}/json/`)
      .then((response) => {
        if (response.data.erro) {
          notifyMessageError('Endereço não encontrado');
        }
        return response;
      })
      .catch(() => {
        notifyMessageError('Endereço não encontrado');
        return null;
      })
  );

  return (
    <SiteContext.Provider
      value={{
        loading,
        notifyMessage,
        getAddressByZipCode,
        setLoading,
        setNotifyMessage,
        closeNotifyMessage,
        notifyMessageError,
        notifyMessageSave
      }}
    >
      { children }
    </SiteContext.Provider>

  );
};

export default SiteProvider;
