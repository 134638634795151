import { Outlet } from 'react-router-dom';
import { Alert, experimentalStyled, Snackbar } from '@material-ui/core';
import { SiteContext } from 'src/context/SiteContext';
import { useContext } from 'react';
import MainNavbar from './MainNavbar';

const MainLayoutRoot = experimentalStyled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);
const MainLayoutWrapper = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 64
});

const MainLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const MainLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const MainLayout = () => {
  const { notifyMessage, setNotifyMessage } = useContext(SiteContext);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotifyMessage({
      ...notifyMessage,
      open: false
    });
  };
  return (
    <MainLayoutRoot>
      <MainNavbar />
      <MainLayoutWrapper>
        <MainLayoutContainer>
          <MainLayoutContent>
            <Outlet />
            <Snackbar
              open={notifyMessage.open}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert onClose={handleClose} severity={notifyMessage.type}>
                {notifyMessage.text}
              </Alert>
            </Snackbar>
          </MainLayoutContent>
        </MainLayoutContainer>
      </MainLayoutWrapper>
    </MainLayoutRoot>
  );
};

export default MainLayout;
