import { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import {
  ThumbsUp as ThumbsUpIcon,
  ThumbsDown as ThumbsDownIcon,
  Edit as EditIcon,
  Trash2 as DeleteIcon,
} from 'react-feather';
import getInitials from 'src/utils/getInitials';
import { useNavigate } from 'react-router';
import DeleteModal from '../modal/DeleteModal';

const UserListResults = ({
  users,
  exclude,
  paginate,
  ...rest
}) => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: null,
    text: 'Tem certeza que deseja excluir este Cliente ?',
  });

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    paginate((newPage + 1));
  };

  const excludeUser = async (id) => {
    const confirm = await exclude(id);
    if (confirm) {
      setDeleteModal({
        ...deleteModal,
        open: false
      });
    }
  };

  const deleteUser = (id) => {
    setDeleteModal({
      ...deleteModal,
      id,
      open: true,
      confirm: excludeUser,
      cancel: () => { }
    });
  };

  const buttonEdit = (id) => {
    navigate(`/app/user/${id}`, { replace: true });
  };
  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Imagem
                </TableCell>
                <TableCell>
                  Nome
                </TableCell>
                <TableCell>
                  E-mail
                </TableCell>
                <TableCell>
                  Ativo
                </TableCell>
                <TableCell>
                  Editar
                </TableCell>
                <TableCell>
                  Excluir
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.data.slice(0, limit).map((user) => (
                <TableRow
                  hover
                  key={user.id}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Avatar
                        src={user.image !== '' ? user.image : `${process.env.REACT_APP_URL}/images/no-image.png`}
                        sx={{ mr: 2 }}
                      >
                        {getInitials(user.name)}
                      </Avatar>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {user.name}
                  </TableCell>
                  <TableCell>
                    {user.email}
                  </TableCell>
                  <TableCell>
                    {user.verified ? <ThumbsUpIcon color="#05ffa1" /> : <ThumbsDownIcon color="#e34234" />}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => { buttonEdit(user.id); }} variant="contained" color="primary">
                      <EditIcon />
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => { deleteUser(user.id); }} variant="contained" color="error">
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={users.meta.total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[]}
      />
      <DeleteModal modal={deleteModal} />
    </Card>
  );
};

UserListResults.propTypes = {
  users: PropTypes.array.isRequired,
  exclude: PropTypes.array.isRequired,
  paginate: PropTypes.array.isRequired
};

export default UserListResults;
