import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PropTypes from 'prop-types';

const TotalCustomers = (props) => {
  const { amount } = props;
  return (
    <Card {...props}>
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              TOTAL CLIENTES
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {amount && amount.amount !== undefined ? amount.amount : 0}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: green[600],
                height: 56,
                width: 56
              }}
            >
              <PeopleIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box
          sx={{
            pt: 2,
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <div>&nbsp;</div>
        </Box>
      </CardContent>
    </Card>
  );
};

TotalCustomers.propTypes = {
  amount: PropTypes.object,
};
export default TotalCustomers;
